import ApiBooking from "../api/ApiBooking";
import { updateSlotTimes } from "../reducers/PageBooking/PageBookingActions";

const apiBooking = new ApiBooking({});

export async function loadSlotTime({dispatch, date, dates, days}){
    const data = await apiBooking.getSlotTimes({date, dates, days});
    if(data){
        //let list = {};
        //data.forEach(item => list[item.slug] = item);
        dispatch(updateSlotTimes(data));
    }
    return data;
}