import React from "react";
import "./styles.css";

export default function BookingPanelId({type, bookingId}){
    return (
        <div className="booking-panel__content-header-id">
            <p className="booking__panel-order-id">
                {`ID: ${bookingId ? bookingId : 'Новое'}`}
            </p>
        </div>
    );
}