import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./__header";
import Content from "./__content";
import Footer from "./__footer";
import ModalYesNo from "../modal-yes-no/modal-yes-no";
import Loading from "./__loading";

import { loadSlotTime } from "../../reducerApi/Booking";
import { setIsLoadUpdate, clearByRequest, setBookingId } from "../../reducers/BookingPanel/BookingPanelActions";
import createBooking from "./actions/createBooking";
import updateBooking from "./actions/updateBooking";
import cancelBooking from "./actions/cancelBooking";
import { loadDataOrders } from "../../reducerApi";
import "./styles.css";

export default function BookingPanel(){
    const dispatch = useDispatch();
    const [valids, setValids] = useState({});
    const [modalCancel, setModalCancel] = useState(false);
    const [modalCancelAndCopy, setModalCancelAndCopy] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [actionStatus, setActionStatus] = useState(null);
    const isSearch = useSelector((state) => state.pageBooking.isSearch);
    const bookingPanel = useSelector((state) => state.bookingPanel);
    const bookingTypes = useSelector((state) => state.list.bookingTypes);
    const type = (bookingPanel.typeId in bookingTypes) ? bookingTypes[bookingPanel.typeId] : null;
    const actionSuccess = async (actionType) =>{
        dispatch(setIsLoadUpdate(true));
        if(bookingPanel.orderId) loadDataOrders({dispatch, ids: [bookingPanel.orderId]})
        if(!isSearch) loadSlotTime({dispatch, date: bookingPanel.date });
        if(actionType !== "cancel-and-copy") dispatch(clearByRequest());
        else dispatch(setBookingId(null));
    }
    const actionError = async (actionType) =>{}
    const actionCreate = async() =>{
        setActionStatus('load');
        setActionType("create");
        let result = await createBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionUpdate = async() =>{
        setActionStatus('load');
        setActionType("update");
        let result = await updateBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionCancel = async() =>{
        setActionStatus('load');
        setActionType("cancel");
        let result = await cancelBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionCancelAndCopy = async() =>{
        setActionStatus('load');
        setActionType("cancel-and-copy");
        let result = await cancelBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    return <div className="booking-panel">
        {actionStatus ? 
            <Loading 
                type={actionType} 
                status={actionStatus}
                actionSuccess={actionSuccess}
                actionError={actionError}
                close={()=>{
                    setActionStatus(null);
                    setActionType(null);
                }}
            /> 
        : null}
        <Header valids={valids} type={type}/>
        <Content valids={valids} type={type}/>
        <Footer 
            valids={valids}
            type={type}
            actionCreate={actionCreate}
            actionUpdate={actionUpdate}
            actionCancel={()=>setModalCancel(true)}
            actionCancelAndCopy={()=>setModalCancelAndCopy(true)}
        />
        <ModalYesNo
            title="Отменить бронирование"
            isOpenModal={modalCancel}
            closeModal={()=>setModalCancel(false)}
            actionBtnYes={()=>{
                actionCancel();
                setModalCancel(false);
            }}
            actionBtnNo={()=>setModalCancel(false)}
        />
        <ModalYesNo
            title="Отменить бронирование и копировать"
            isOpenModal={modalCancelAndCopy}
            closeModal={()=>setModalCancelAndCopy(false)}
            actionBtnYes={()=>{
                actionCancelAndCopy();
                setModalCancelAndCopy(false);
            }}
            actionBtnNo={()=>setModalCancelAndCopy(false)}
        />
    </div>
}