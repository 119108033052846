import ApiBooking from "../../../api/ApiBooking";
import validation from "./validation";

const apiBooking = new ApiBooking({});

export default async function updateBooking({
    bookingPanel,
    setValids,
    type,
}){
    let data = validation({bookingPanel, setValids, type});
    if(!data) return null;
    data.id = bookingPanel.bookingId;
    const result = await apiBooking.updateBooking({...data, type});
    if(result){
        return true;
    }
    else{
        if(apiBooking.errors)
            setValids(apiBooking.errors.valids);
        else setValids({errors: ['Возникла ошибка']});
        return false;
    }
    
}