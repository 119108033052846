export default function validation({
    error = {},
    data = {},
    bookingPanel,
    setValids,
    type,
}){
    data.date = bookingPanel.date;
    data.timeId = bookingPanel.slotTimeId;
    data.minutes = bookingPanel.minutes;
    data.comment = (bookingPanel.comment) ? bookingPanel.comment : null;
    data.typeId = bookingPanel.typeId;
    data.userId = bookingPanel.userId;
    data.isWriteOnTop = bookingPanel.isWriteOnTop;
    if(!data.date) error.date = ['Выберите дату'];
    if(!data.timeId && data.timeId !== 0) error.slotTimeId = ['Выберите время'];
    if(!data.minutes || data.minutes <= 0)  error.minutes = ['Выберите кол-во минут'];
    if(!data.typeId) error.typeId = ['Выберите тип'];
    if(!data.userId) error.userId = ['Выберите пользователя'];
    if(type && type.slug === 'balance'){
        data.userBalanceId = bookingPanel.userBalanceId;
        if(!data.userBalanceId) error.userBalanceId = ['Выберите баланс пользователя'];
    }else if(type && type.slug === 'certificate' && !bookingPanel.bookingId){
        data.orderId = bookingPanel.orderId;
        if(!data.orderId) error.orderId = ['Выберите сертификат'];
    }else if(type && type.slug === 'booking'){
        data.paymentTypeId = bookingPanel.paymentTypeId;
        data.paymentStatusId = bookingPanel.paymentStatusId;
        data.productId = bookingPanel.productId;
        if(!data.paymentTypeId) error.paymentTypeId = ['Выберите тип оплаты'];
        if(!data.paymentStatusId) error.paymentStatusId = ['Выберите статус оплаты'];
        if(!data.productId) error.productId = ['Выберите тариф'];
    }
    setValids(error);
    if(Object.keys(error).length)
        return null;
    return data;
}