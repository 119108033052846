import { combineReducers } from "redux";

import App from "./App/AppReducers";
import Data from "./Data/DataReducers";
import PageBooking from "./PageBooking/PageBookingReducers";
import BookingPanel from "./BookingPanel/BookingPanelReducers";
import List from "./List/ListReducers";
import Profile from "./Profile/ProfileReducers";

const reducers = combineReducers({
    app: App,
    data: Data,
    pageBooking: PageBooking,
    bookingPanel: BookingPanel,
    list: List,
    profile: Profile,
});
export default reducers;
