import React from "react";
import { useSelector } from "react-redux";
import { LinearProgress  } from '@mui/material';

function LoaderApp() {
  const isLoad = useSelector((state) => state.app.isLoad);
  return <>{isLoad ? 
        <LinearProgress sx={{position: 'absolute', left: 0, right: 0, height: '7px'}} color="success"/> 
        : null}
    </>
}

export default LoaderApp;
