import React from "react";

import Typography from "@mui/material/Typography";
import Button from "../button";
import Modal from "../modal";


export default function ModalAction({
    title,
    children,
    actionBtn,
    labelBtn,
    ...other
}){
    return (
        <Modal
            {...other}
        >
            <Typography variant="h6" component="h2">{title}</Typography>
            <div>
                {children}
            </div>
            {labelBtn ? 
                <Button 
                    variant="contained"
                    size="small"
                    onClick={actionBtn}
                >{labelBtn}</Button> 
            : null }
        </Modal>
    );
}