import { formatterProduct } from './Product';
export function formatterOrder(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        phone: data.phone,
        paymentTypeId: (data.payment_type_id) ? parseInt(data.payment_type_id) : null,
        paymentStatusId: (data.payment_id) ? parseInt(data.payment_id) : null,
        productId: (data.products && data.products[0] && data.products[0].product) ? parseInt(data.products[0].product.id) : null,
        email: data.email,
        hash: data.hash,
        total: data.total,
        totalMinutes: parseInt(data.totalMinutes),
        certificate: (data.certificate) ? formatterOrderCertificate(data.certificate) : null,
        marketing: (data.marketing) ? formatterOrderMarketing(data.marketing) : null,
        //paymentType: (data.payment_type) ? formatterPaymentType(data.payment_type) : null,
        //paymentStatus: (data.payment_status) ? formatterPaymentStatus(data.payment_status) : null,
        product: (data.products && data.products[0] && data.products[0].product) ? formatterProduct(data.products[0].product) : null,
    }
}
export function formatterOrderCertificate(data){
    return {
        id: parseInt(data.id),
        code: data.code,
        isActive: data.is_active,
        dateEnd: (data.date_end) ? new Date(data.date_end) : null,
        dateUse: (data.date_use) ? new Date(data.date_use) : null,
        isManualCheck: data.is_manual_check,
    }
}
export function formatterOrderMarketing(data){
    return {
        id: parseInt(data.id),
        urlAmoCrm: data.urlAmoCrm,
    }
}
export function formatterPaymentType(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        slug: data.slug,
        isHidden: data.is_hidden,
    }
}
export function formatterPaymentStatus(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        slug: data.slug,
    }
}