import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../button";
import AccountCircle from '@mui/icons-material/AccountCircle';
import ModalUser from "../../users/__index/users__index-modal";
import { setUserId } from "../../../reducers/Data/DataActions";
import { updateUser } from "../../../reducers/List/ListActions";

import style from "./style";

export default function BookingHeaderUser({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.data.userId);
    const user = useSelector((state) => (userId && userId in state.list.users) ? state.list.users[userId] : null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openModal = () =>{ setIsOpenModal(true)}
    const closeModal = () =>{ setIsOpenModal(false)}
    const actionSelectUser = (user) => {
        dispatch(updateUser(user.id, user));
        dispatch(setUserId(user.id));
        closeModal();
    }
    return (<>
            <Button
                id={'modalUserBtn'}
                startIcon={<AccountCircle/>}
                variant="outlined"
                sx={style.button}
                onClick={openModal}
            >
                {(user) ? user.surnameNM : 'Пользователь'}
            </Button>
            <ModalUser
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                actionSelectUser={actionSelectUser}
            />
        </>
    );
}