import ApiBooking from "../../../api/ApiBooking";
const apiBooking = new ApiBooking({});

export default async function cancelBooking({
    bookingPanel,
    setValids,
    type,
}){
    let data = {};
    data.id = bookingPanel.bookingId;
    const result = await apiBooking.cancelBooking({...data, type});
    console.log(result);
    if(result){
        return true;
    }
    else{
        if(apiBooking.errors)
            setValids(apiBooking.errors.valids);
        else setValids({errors: ['Возникла ошибка']});
        return false;
    }
}