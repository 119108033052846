import React from "react";
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import User from "../__user";
import Product from "../__product";
import Certificate from "../__certificate";
import UserBalance from "../__user-balance";

import "./styles.css";

export default function BookingPanelContentCenter({type, valids = {} }){
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);
    const orderId = useSelector((state) => state.bookingPanel.orderId);
    let typeSlug = (type) ? type.slug : '';
    return  <div className="booking-panel__content-center">
        <Box>
            <div className="booking-panel__content-center-item">
                <User errors={valids.userId} isEdit={!bookingId}/>
            </div>
            {(type.slug === "booking")?
                <div className="booking-panel__content-center-item">
                    <Product errors={valids.productId}/>
                </div>
            : null}
            {(type.slug === "certificate")?
                <div className="booking-panel__content-center-item">
                    <Certificate errors={valids.orderId} isEdit={!(bookingId && orderId && typeSlug === 'certificate')}/>
                </div>
            : null}
            {(type.slug === "balance")?
                <div className="booking-panel__content-center-item">
                    <UserBalance errors={valids.balanceUserId} isEdit={!bookingId}/>
                </div>
            : null}
        </Box>
    </div>
}