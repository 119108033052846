import React from "react";
import { useSelector } from "react-redux";

import Button from "../../button/button";
import FormControl from '@mui/material/FormControl';
import MessageErrors from "../../message/__errors/message__errors";
import Comment from "../__comment";
import WriteOnTop from "../__write-on-top";

import style from "./style";

export default function BookingPanelFooter({type, valids, actionCreate, actionUpdate, actionCancel, actionCancelAndCopy}){
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);

    return <div style={style.wrapper}>

        <Comment errors={valids.comment}/>
        <div>
            <FormControl error={(valids.errors) ? true : false}>
                <MessageErrors errors={valids.errors}/>
            </FormControl>
        </div>
        <WriteOnTop errors={valids.isWriteOnTop}/>
        {(bookingId) ?
        <>
            <Button
                variant="contained"
                color="success"
                style={style.btnSave}
                onClick={actionUpdate}
            >
                Сохранить изменения
            </Button>
            <div>
                <Button
                    variant="contained"
                    color="error"
                    style={style.btnCancel}
                    onClick={actionCancel}
                >
                    Отменить
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={style.btnCancelAndCopy}
                    onClick={actionCancelAndCopy}
                >
                    Отменить и копировать
                </Button>
            </div>
        </>
        :
        <Button
            variant="contained"
            color="success"
            style={style.btnCreate}
            onClick={actionCreate}
        >
            Создать бронирование
        </Button>
        }
    </div>
}