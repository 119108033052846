import React from "react";

import Modal from "../modal";
import Button from "../button";
import "./styles.css";

export default function ModalYesNo({
    actionBtnYes,
    actionBtnNo,
    ...other
}){
    return (
        <Modal
            {...other}
        >
            <div className="modal-yes-no__footer">
                <div className="modal-yes-no__footer-left">
                    <Button 
                        color="success"
                        variant="outlined"
                        size="small"
                        onClick={actionBtnYes}
                    >Да</Button>
                </div>
                <div className="modal-yes-no__footer-right">
                    <Button 
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={actionBtnNo}
                    >Нет</Button>
                </div>
            </div>
        </Modal>
    );
}