import React from "react";
import { useSelector } from "react-redux";

import OrderId from "../__id";
import BookingPanelDatetime from "../__content-datetime-group";

import Minute from "../__minute";
import ContentRight from "../__content-right";
import ContentCenter from "../__content-center";

import "./styles.css";

export default function BookingPanelContent({type, valids}){
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);
    return <div className="booking-panel__content">
        <div className="booking-panel__content-header">
            <OrderId bookingId={bookingId}/>
        </div>
        <BookingPanelDatetime valids={valids} type={type}/>
        <div className="booking-panel__content-group">
            <Minute errors={valids.minutes}/>
            <ContentRight valids={valids} type={type}/>
        </div>
        <ContentCenter valids={valids} type={type}/>
    </div>
}