import React, { useRef } from "react";
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';


import CONFIG from "../../../inc/Config";
import './styles.css';

const keys = {
    userBalanceId: 'userBalance',
    userId: 'user',
}
export default function BookingsExport({params, children}){
    const linkRef = useRef(null);
    const token = useSelector((state) => state.app.token);
    let url = `${CONFIG.serverUrl}/api/v0/admin/bookings/export`;
    const actionDownload = () => {
        if(linkRef) linkRef.current.click();
    }
    let _params = '';
    if(params){
        Object.keys(params).forEach((key)=>{
            let name = keys[key];
            let value = params[key];
            _params = `${_params}&${name}=${value}`;
        })
    }
    if(_params && token){
        url = `${url}?token=${token}${_params}`;
    }else return null;
    return <>
        <IconButton onClick={actionDownload} title="Скачать историю по бронированиям">
            <GetAppIcon color="primary"/>
        </IconButton>
        {children}
        <a className="bookings__export" href={url} ref={linkRef}>Скачать</a>
    </>
}
