import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import InputNumber from '../../input/__number';
import InputSelect from '../../input/__select';
import ModalAction from '../../modal-action';
import ApiBooking from "./../../../api/ApiBooking";
import InputText from "../../input/__text";
import InputAdornment from "@mui/material/InputAdornment";
import { loadUsers } from "../../../reducerApi/List";
import style from "./style";
import "./styles.css";
const apiBooking = new ApiBooking({});

export default function UserBalancesDepositModal({ actionSuccess, type, userBalance, ...other}){
    const dispatch = useDispatch();
    const paymentTypes = useSelector((state) => state.list.paymentTypes);
    const [paymentType, setPaymentType] = useState(null);
    const [minutes, setMinutes] = useState(0);
    const [comment, setComment] = useState("");
    const [valids, setValids] = useState({});
    const typeId = (type === "DEPOSIT") ? 1 : 2;
    const actionEditMinutes = (e) => {
        let value = parseInt(e.target.value);
        if(value > 0) setMinutes(value)
        else setMinutes(0);
    }
    const sendTime = async () =>{
        if(minutes <= 0) return null;
        const result = await apiBooking.createTime({
            minutes,
            typeId,
            paymentTypeId: (paymentType) ? paymentType.id : null,
            balanceUserId: userBalance.id,
            comment,
        });
        if(result){
            loadUsers({dispatch, ids: [userBalance.userId]});
            if(actionSuccess) actionSuccess(result);
        } else setValids(apiBooking.errors.valids);
    }
    let items = {};
    if(type === "DEPOSIT"){
        Object.keys(paymentTypes).forEach((key)=>{
            let item = paymentTypes[key];
            if(!paymentType && item.slug === 'cash') setPaymentType(item);
            items[key] = {
                onClick: () => {setPaymentType(item)},
                label: item.name,
            }
        })
    }
    const _setComment = (e) => {
        setComment(e.target.value);
    }
    return <ModalAction
        {...other}
        id="user-balances-deposit"
        title={(type === "DEPOSIT") ? "Пополнить" : "Списать"}
        labelBtn={(type === "DEPOSIT") ? "Пополнить" : "Списать"}
        actionBtn={sendTime}
    >
        <div className="user-balances__operation">
            <div className="user-balances__operation-col-50">
                <InputNumber
                    label="Минут"
                    variant="standard"
                    value={minutes}
                    onChange={actionEditMinutes}
                    sx={style.input}
                    errors={valids.minutes}
                />
            </div>
            {(type === "DEPOSIT") ?
            <div className="user-balances__operation-col-50">
                <InputSelect
                    label="Тип"
                    id="booking-payment-type"
                    value={(paymentType) ? paymentType.id : null}
                    items={items}
                    errors={valids.paymentTypeId}
                />
            </div>: null}
            <div className="user-balances__operation-col-100">
                <InputText
                    {...other}
                    label="Комментарий"
                    multiline
                    rows={(comment && comment.length > 20) ? 3 : 1}
                    style={style.inputComment}
                    value={comment}
                    onChange={_setComment}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                    ),
                    }}
                    variant="outlined"
                />
            </div>
        </div>
    </ModalAction>
}