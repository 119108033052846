import React from "react";

import FormControl from '@mui/material/FormControl';
import MessageErrors from "../../message/__errors";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function InputText({errors, value, onChange, label, ...other}){
    return <FormControl error={(errors) ? true : false}>
        <FormControlLabel 
            label={label}
            control={
                <Checkbox
                    {...other}
                    checked={value}
                    onChange={onChange}
                    size="small"
                />
            } 
        />
        <MessageErrors errors={errors}/>
    </FormControl>
}