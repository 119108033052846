import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
// import CommentIcon from "@mui/icons-material/Comment";
import InputText from "../../input/__text";
import style from "./style";

import { setComment } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelComment({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const comment = useSelector((state) => state.bookingPanel.comment);
    const _setComment = (e) =>{
        let value = e.target.value;
        dispatch(setComment(value));
    }
    return (
        <InputText
            {...other}
            label="Комментарий"
            multiline
            rows={(comment && comment.length > 20) ? 3 : 1}
            style={style.input}
            value={comment}
            onChange={(!isEdit)? null : _setComment}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
            ),
            }}
            variant="outlined"
        />
    );
}