import formatterBookingUserBalance from "./BookingUserBalance";
export function formatterUser(data){
    let userBalances = {};
    if(data.booking_user_balances){
        data.booking_user_balances.forEach((item) => {
            userBalances[item.id] = formatterBookingUserBalance(item);
        });
    }
    return {
        id: parseInt(data.id),
        name: data.name,
        surname: data.surname,
        middleName: data.middle_name,
        surnameNM: data.surnameNM,
        email: data.email,
        phone: data.phone,
        userBalances: userBalances,
    }
}
export function formatterUserErrors(data){
    let errorValids = {};
    let valids = {
        name: 'name',
        surname: 'surname',
        middle_name: 'middleName',
        email: 'email',
        phone: 'phone',
    };
    Object.keys(data).forEach((key)=>{
        let item = data[key];
        if(key in valids) errorValids[valids[key]] = item;
    })
    return {
        valids: errorValids,
    };
}