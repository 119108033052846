import React  from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "../../inputs/checkbox";
import { setIsWriteOnTop } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelWriteOnTop({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const isWriteOnTop = useSelector((state) => state.bookingPanel.isWriteOnTop);
    const actionEdit = () => {dispatch(setIsWriteOnTop(!isWriteOnTop))}
    return <Checkbox 
        {...other}
        label="Записать поверх" 
        value={isWriteOnTop}
        onChange={actionEdit}
    />
}