import React, {useState, useEffect} from "react";

import ModalList from '../../modal-list';
import ApiBooking from "./../../../api/ApiBooking";
import DateLib from "../../../inc/DateLib";

const apiBooking = new ApiBooking({});

export default function BookingTimesIndexModal({isOpenModal, userBalance,  ...other}){
    const [bookingTimes, setBookingTimes] = useState([]);
    const loadBookingTimes = async ({balanceUser}) => {
        const data = await apiBooking.getBookingTimes({balanceUser});
        if(data) setBookingTimes(data);
    }
    const userBalanceId = userBalance.id;
    useEffect(()=>{
        if(isOpenModal){
            if(userBalanceId) loadBookingTimes({balanceUser: userBalanceId});
        }
    }, [userBalanceId, isOpenModal]);
    return <ModalList
        {...other}
        isOpenModal={isOpenModal}
        id="users"
        title={userBalance.type}
        headers={['№','Минут', 'Тип', 'Дата', 'Комментарий', 'Бронирование', 'Дата Бронирования',]}
        list={bookingTimes.map((item)=>{
            return {columns:[
                item.id,
                item.minutes,
                (item.type) ? item.type.name : '',
                DateLib.formatterDateTime(item.createdAt),
                item.comment,
                (item.booking) ? item.booking.id : '',
                (item.booking) ? DateLib.formatterDateTime(item.booking.slotTimeDateTimeStart) : '',
            ]};
        })}
    />
}