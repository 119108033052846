import TypeConst from "./AppConst";

export default function todosReducer(state = {
  isAuth: false, 
  isLoad: false, 
  token: null,
}, action) {
  switch (action.type) {
    case TypeConst.UPDATE_STATUS_LOAD:
        return {
            ...state,
            isLoad: action.isLoad
        };
    case TypeConst.UPDATE_TOKEN:
        return {
            ...state,
            token: action.token,
            isAuth: (action.token != null),
        };
    default:
        return state;
  }
}
