import React from "react";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import MessageErrors from "../../message/__errors/message__errors";

export default function InputSelect({label, sx ={}, sxFormControl = {}, items = [], className="", errors, ...other}){
    return <FormControl 
        sx={{ m: 0.5, width: 150, margin: '5px', ...sxFormControl}} 
        size="small" 
        className={className}
        error={(errors) ? true : false}
    >
        <InputLabel>{label}</InputLabel>
        <Select
            {...other}
            className={className}
            label={label}
            sx={{ height: '34px', ...sx}}
        >
            {Object.keys(items).map((key)=>{
                let item = items[key];
                if(typeof item == 'object')
                    return <MenuItem value={key} key={key} onClick={item.onClick}>{item.label}</MenuItem> 
                return <MenuItem value={key} key={key}>{item}</MenuItem>
            })}
        </Select>
        <MessageErrors errors={errors}/>
    </FormControl>

}