import TypeConst from "./AppConst";

export function setIsLoad(isLoad){
    return {
        type: TypeConst.UPDATE_STATUS_LOAD,
        isLoad: isLoad,
    }
}
export function setToken(token){
    return {
        type: TypeConst.UPDATE_TOKEN,
        token: token,
    }
}