import TypeConst from "./PageBookingConst";

export function setIsSearch(isSearch){
    return {
        type: TypeConst.SET_IS_SEARCH,
        isSearch: isSearch,
    }
}
export function setDate(date){
    return {
        type: TypeConst.SET_DATE,
        date: date,
    }
}
export function updateSlotTimes(list){
    return {
        type: TypeConst.UPDATE_SLOT_TIMES,
        slotTimes: list,
    }
}