import TypeConst from "./DataConst";

export default function todosReducer(state = {
  userId: null,
}, action) {
  switch (action.type) {
    case TypeConst.SET_USER_ID:
      return {
          ...state,
          userId: action.userId,
      };
    default:
        return state;
  }
}
