import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputSelect from "../__select";
import { setPaymentTypeId } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelPaymentType({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const paymentTypes = useSelector((state) => state.list.paymentTypes);
    const paymentTypeId = useSelector((state) => state.bookingPanel.paymentTypeId);
    let items = [];
    Object.keys(paymentTypes).forEach((key)=>{
        let item = paymentTypes[key];
        items[key] = {
            onClick: (!isEdit)? null : () =>{
                dispatch(setPaymentTypeId(item.id));
            },
            label: item.name,
        }
    });
    return <InputSelect
        {...other}
        label="Оплата"
        value={paymentTypeId}
        items={items}
    />
}