import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./styles.css";

export default function BookingPanelLoading({type, status = "load", actionSuccess, actionError, close}){
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);
    const actionClose = async ({type, status, close, actionSuccess, actionError}) =>{
        if(status === "success") actionSuccess(type);
        else if(status === "error") actionError(type);
        close();
    }
    let message = "";
    if(type === "create") message = (status === "success") ? "Успешно создано" : "Ошибка при создании";
    else if(type === "update") message = (status === "success") ? `Бронирование №${bookingId} успешно обновлено` : "Ошибка при обновлении";
    else if(type === "cancel" ||
        type === "cancel-and-copy") message = (status === "success") ? "Успешно отменено" : "Ошибка при отмене";
    useEffect(()=>{
        const closeSuccess = async () =>{
            await new Promise(resolve => setTimeout(resolve, 700));
            actionClose({type, status, close, actionSuccess, actionError});
        }
        if(status === "success") closeSuccess();
    }, [type, status, close, actionSuccess, actionError]);
    if(!close) return null;
    return <div className="booking-panel__loading" onClick={() => actionClose({type, status, close, actionSuccess, actionError})}>
        {status === "load"?
            <div className="booking-panel__loading-loader"></div>
        : null}
        {status === "error"?
            <div className="booking-panel__loading-error">{message}</div>
        : null}
        {status === "success"?
            <div className="booking-panel__loading-success">{message}</div>
        : null}
    </div>
}