import React from "react";

import CreateIcon from '@mui/icons-material/Create';
import Button from "../../button";
import FormControl from '@mui/material/FormControl';
import MessageErrors from "../../message/__errors/message__errors";

import style from "./style";
import styleError from "../../message/__errors/style";

export default function BookingPanelButton({actionEdit, Icon, errors, ...other}){
    let styleButton = (errors) ? styleError.button : {};
    return <FormControl error={(errors) ? true : false}>
        <div>
            <Button 
                {...other}
                startIcon={<Icon/>}
                variant="outlined" 
                sx={{...style.button, ...styleButton}}
            />
            {(actionEdit)? 
            <CreateIcon 
                sx={style.iconEdit}
                onClick={actionEdit}
            />
            : null}
        </div>
        <MessageErrors errors={errors}/>
    </FormControl>
}