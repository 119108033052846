import React from "react";
import { useDispatch, useSelector, batch } from "react-redux";

import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import CachedIcon from '@mui/icons-material/Cached';
import InputDate from '../../input/__date';

import { loadDataSlotTime } from "../../../reducerApi";
import { setDate as setDatePageBooking } from "../../../reducers/PageBooking/PageBookingActions";
import { setDate as setDateBookingPanel } from "../../../reducers/BookingPanel/BookingPanelActions";
//import { setDate as setDateBookingPanel } from "../../../reducers/BookingPanel/BookingPanelActions";
import DateLib from '../../../inc/DateLib';

import style from "./style";
import './styles.css';



export default function BookingHeaderLeft(){
    const dispatch = useDispatch();
    const date = useSelector((state) => state.pageBooking.date);
    const setDate = (date) =>{
        date = DateLib.formatterDate(date);
        batch(()=>{
            dispatch(setDatePageBooking(date));
            dispatch(setDateBookingPanel(date));
        });
    }
    const onChangeDate = (e) => {
        let _date = new Date(e.target.value);
        if(!DateLib.equals(_date, date)) setDate(_date);
    }
    const actionSetHome = (e) => {
        let _date = new Date();
        if(!DateLib.equals(_date, date)) setDate(_date);
    }
    const actionUpdate = async (e) => {
        loadDataSlotTime({dispatch, date});
    }
    return <div className="booking-header__left">
        <InputDate
            label="Дата"
            inputSx={style.inputDate}
            value={(date) ? date : new Date()}
            //min={new Date()}
            onChange={onChangeDate}
        />
        <IconButton color="primary" aria-label="upload picture" component="label" onClick={actionSetHome}>
            <HomeIcon />
        </IconButton>
        <IconButton color="primary" aria-label="upload picture" component="label" onClick={actionUpdate}>
            <CachedIcon />
        </IconButton>
    </div>
    
}