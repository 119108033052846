import store from "../reducers/store";

export default class Channel{
    static NAME = "";
    
    constructor({
        channel
    }) {
        this.__channel = channel;
    }
    init(){
        const events = this.events; 
        Object.keys(events).forEach((name)=>{
            let event = events[name];
            this.__channel.bind(name, (dataBase64) => {
                let data = this._encodeData(dataBase64);
                event(data);
            });
        });
    }
    _encodeData(data){
        let jsonStr = atob(data.data);
        return JSON.parse(jsonStr);
    }
    close(){

    }
    get events(){
        return {}
    }
    get store(){
        return store;
    }

}
