import Channel from "../Channel";
import { formatterSlotTimesToDates } from "../../api/Formatter/BookingSlotTime";
import { updateSlotTimes } from "../../reducers/PageBooking/PageBookingActions";

export default class SlotTimes extends Channel{
    static NAME = "admin-slot-times";
    get events(){
        return {
            "update": (data) => this.eventUpdate(data),
        }
    }
    eventUpdate(slotTimes){
        let dates = formatterSlotTimesToDates(slotTimes);
        console.log(slotTimes);
        this.store.dispatch(updateSlotTimes(dates));
        //{dispatch: this.store.dispatch}
    }

}
