import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SyncIcon from '@mui/icons-material/Replay';
import InputText from '../../input/__text';
import InputPhone from "../../input/__phone";
import InputEmail from "../../input/__email";

import ModalEdit from "../../modal-edit";
import ModalMessage from '../../modal-message';
import UserBalancesIndexModal from "../../user-balances/__index/user-balances__index-modal";
import ApiUsers from "../../../api/Admin/Users";
import { updateUser } from "./../../../reducers/List/ListActions";

import style from "./style";



export default function UserEditModal({ userId, actionSuccessUpdate = null, ...other}){
    const dispatch = useDispatch();
    const [isShowUserBalances, setIsShowUserBalances] = useState(false);
    const user = useSelector((state) => (userId in state.list.users) ? state.list.users[userId] : null );
    const [name, setName] = useState((user.name) ? user.name : '');
    const [surname, setSurname] = useState((user.surname) ? user.surname : '');
    const [middleName, setMiddleName] = useState((user.middleName) ? user.middleName : '');
    const [email, setEmail] = useState((user.email) ? user.email : '');
    const [phone, setPhone] = useState((user.phone) ? user.phone : '');
    const [valids, setValids] = useState({});

    const actionEditName = (e) => {setName(e.target.value)}
    const actionEditSurName = (e) => {setSurname(e.target.value)}
    const actionEditMiddleName = (e) => {setMiddleName(e.target.value)}
    const actionEditEmail = (e) => {setEmail(e.target.value)}
    const actionEditPhone = (e) => {
        let value = e.target.value;
        if(value.length >= 0 && value.length <= 11) setPhone(value)
    }
    const actionUpdateUser = async () => {
        setValids({});

        const apiUsers = ApiUsers.Update(user.id, {name, surname, middleName, email, phone});
        const result = await apiUsers.getResponse();
        if(result){
            dispatch(updateUser(result.id, result));
            if(actionSuccessUpdate) actionSuccessUpdate(result);
        }else setValids(apiUsers.errors.valids);
    }
    const actionSyncUser = async () => {
        const apiUsers = ApiUsers.Show(user.id);
        const result = await apiUsers.getResponse();
        if(result){
            dispatch(updateUser(result.id, result));
            setName((result.name) ? result.name : '');
            setSurname((result.surname) ? result.surname : '');
            setMiddleName((result.middleName) ? result.middleName : '');
            setEmail((result.email) ? result.email : '');
            setPhone((result.phone) ? result.phone : '');
        }
    }
    if(!user) return <ModalMessage {...other} message="Пользователь не найден"/>
    return <>
        <ModalEdit
            {...other} 
            title={<>
                Пользователь
                <SyncIcon onClick={()=> actionSyncUser()}/>
                <AccountBalanceIcon onClick={()=> setIsShowUserBalances(true)}/>

            </>}
            actionBtnSave={actionUpdateUser}
        >
            <InputText
                label="Фамилия"
                variant="standard"
                value={surname}
                onChange={actionEditSurName}
                sx={style.input}
                errors={valids.surname}
            />
            <InputText
                label="Имя"
                variant="standard"
                value={name}
                onChange={actionEditName}
                sx={style.input}
                errors={valids.name}
            />
            <InputText
                label="Отчество"
                variant="standard"
                value={middleName}
                onChange={actionEditMiddleName}
                sx={style.input}
                errors={valids.middleName}
            />
            <InputEmail
                label="Email"
                variant="standard"
                value={email}
                onChange={actionEditEmail}
                sx={style.input}
                errors={valids.email}
            />
            <InputPhone
                label="Телефон"
                variant="standard"
                value={(phone) ? `${phone}`: '7'}
                onChange={actionEditPhone}
                sx={style.input}
                errors={valids.phone}
            />
        </ModalEdit>
        <UserBalancesIndexModal
            isOpenModal={isShowUserBalances}
            closeModal={() => setIsShowUserBalances(false)}
            userId={user.id}
        />
    </>
}