import React from "react";

import TextField from "@mui/material/TextField";

export default function InputText({value, onChange, errors, ...other}){
    const actionEditPhone = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        onChange(e)
    }
    return <TextField
        {...other}
        onChange={actionEditPhone}
        value={`+${value}`}
        error={(errors) ? true : false}
        helperText={errors}
    />
}