import React from "react";
import { useSelector } from "react-redux";

import TimerIcon from "@mui/icons-material/Timer";
import SlotTimesDate from "../../slot-times-date/slot-times-date";
import "./styles.css";

export default function BookingsHeader({date}){
    const roles = useSelector((state) => state.profile.roles);
    const isSearch = useSelector((state) => state.pageBooking.isSearch);
    return <div className="bookings__header-wrapper">
        <div className={`${isSearch ? 'grid-booking-content__header--search' : 'grid-booking-content__header'} bookings__header ${roles.includes('staff') ? "staff" : ""}`}>
            <div className="bookings__header-item sticky-booking-content__header-col-1"></div>
            <div className="bookings__header-item sticky-booking-content__header-col-2"><TimerIcon /></div>
            <div className="bookings__header-item">№</div>
            { roles.includes('admin')
            ? <>
                <div className="bookings__header-item">Тип</div>
                <div className="bookings__header-item">Тариф</div>
                <div className="bookings__header-item">Имя</div>
                <div className="bookings__header-item">E-mail</div>
                <div className="bookings__header-item">Телефон</div>
                <div className="bookings__header-item">Оплата</div>
                {isSearch ?  <div className="bookings__header-item">Статус</div> : null}
                </>
                : <div className="bookings__header-item">Имя</div>
            }
            <div className="bookings__header-item">Комментарий</div>
        </div>
        {date ?
        <div>
            <div>
                <SlotTimesDate date={date}/>
            </div>
        </div>
        : null}
    </div>
}
