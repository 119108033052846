import React, { useState }  from "react";
import { useDispatch } from "react-redux";

import ApiAuth from "./../../api/ApiAuth";
import { setDataLogin } from "../../inc/lib";
import CONFIG from "../../inc/Config";
import Button from '@mui/material/Button';

const apiAuth = new ApiAuth({});

const DEFAULT_USER_FULLNAME = "USER";

export default function Login() {
  const [email, setEmail] = useState(CONFIG._user.email);
  const [password, setPassword] = useState(CONFIG._user.password);
  const dispatch = useDispatch();

  const login = async () => {
    const data = await apiAuth.login({email, password});
    if(data){
      await setDataLogin({
        dispatch: dispatch,
        token: data.token,
        roles: data.roles,
        fullName: data.fullName || DEFAULT_USER_FULLNAME,
    });
    }
  };

  const actionSetEmail = (e) => {
    setEmail(e.target.value);
  }
  const actionSetPassword = (e) => {
    setPassword(e.target.value);
  }
  return (<>
    <div><input type="text" value={email} onChange={actionSetEmail} placeholder="E-mail"/></div>
    <div><input type="password" value={password} onChange={actionSetPassword} placeholder="Пароль"/></div>
    <div><Button onClick={login}>Войти</Button></div>

    </>
  );
}