import ApiInit from "./ApiInit";

export default class ApiAuth extends ApiInit{
    async login({ email, password }, cb) {
        let rdata = await this._query(`auth/login`, "POST", { email, password });
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data;
    }
    async logout(cb) {
        let rdata = await this._query(`auth/logout`, "POST", { token: this.token });
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            if(rdata.error.code === 401) return true;
            return null;
        }
        return true;
    }
    async refresh(cb) {
        let rdata = await this._query(`auth/token/refresh`, "POST", { token: this.token });
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data;
    }
}
