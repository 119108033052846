import React, { useRef, useEffect, useState, useLayoutEffect }  from "react";
import { useSelector } from "react-redux";
import DateLib from "../../inc/DateLib";
import Item from "../slot-time";
import SlotTimesDate from "../slot-times-date/slot-times-date";
import './styles.css';

function SlotTimes({date, parentRef, roles, isActive, isNeighbour}){
    const slotTimesRef = useRef(null);
    const slotTimes = useSelector((state) => state.pageBooking.slotTimes);
    const [isDayOff, setIsDayOff] = useState(() => {
        return DateLib.getWeekdayNumber(date) > 5 ? true : false;
    });
    const bookingTimeIds = useSelector((state) => state.list.bookingTimeIds,
        (oldValue, newValue) => Object.keys(oldValue).length === Object.keys(newValue).length);
    const scrollToPosition = useSelector((state) => {
        if(!isActive) return null;
        let max = Object.keys(state.list.bookingTimeIds).length;
        if(!max) return null;
        let quotient = state.bookingPanel.slotTimeId/max; 
        if(quotient <= 0.3) return "start";
        else if(quotient >= 0.7) return "end";
        return "center";
    });

    useEffect( () => {
        if(slotTimesRef && isActive){
            const scroll = parentRef.current.scrollTop+(parentRef.current.offsetHeight/2);
            const min = slotTimesRef.current.offsetTop;
            const max = min+slotTimesRef.current.offsetHeight;
            if(!(scroll > min && scroll < max) && scrollToPosition){
                slotTimesRef.current.scrollIntoView({behavior: "instant", block: scrollToPosition, inline: "start"});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, slotTimesRef, parentRef, bookingTimeIds]);
    useLayoutEffect(() => {
        const slotTimeForDate = slotTimes[date];
        if (slotTimeForDate) {
            setIsDayOff(slotTimeForDate['0'] ? slotTimeForDate['0'].isDayOff : false);
        } else {
            setIsDayOff(false);
        }
    }, [isDayOff,slotTimes])
    return <div
        className="booking__time-slots"
        ref={slotTimesRef}
        id={`booking__time-slots-${date}`}>
        <div className={`booking__time-slots-title ${isDayOff ? 'booking__time-slots-title-weekend ': '' }`}>
            <SlotTimesDate date={date}/>
        </div>
        {Object.keys(bookingTimeIds).map((key)=>{
            let row = bookingTimeIds[key]
            return <Item
                date={date}
                timeId={row.timeId}
                timeStart={row.timeStart}
                key={key}
                isActive={isActive}
            />
        })}
    </div>
}

export default SlotTimes;