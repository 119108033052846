import React from "react";
import { useDispatch } from "react-redux";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { clear } from "../../../reducers/BookingPanel/BookingPanelActions";

import style from "./style";
import "./styles.css";

export default function BookingPanelHeader({type, valids }){
    const dispatch = useDispatch();
    const actionClose = () =>{
        dispatch(clear());
    }
    return <div className="booking-panel__header">
        <h3 className="booking-panel__header-title">Бронирование</h3>
        <div className="booking-panel__header-close">
            <IconButton style={style.btnClose} 
                aria-label="Close panel" 
                title="Close panel" 
                component="label"
                onClick={actionClose}
            >
                <CloseIcon />
            </IconButton>
        </div>
    </div>
}