import TypeConst from "./BookingPanelConst";
import DateLib from '../../inc/DateLib';

export default function todosReducer(state = { 
  isOpen: false,
  isLoadUpdate: false,
  bookingId: null,
  date: DateLib.formatterDate(new Date()),
  minutes: 0,
  slotTimeId: null,
  typeId: null,
  userId: null,
  userBalanceId: null,
  orderId: null,
  productId: null,
  paymentTypeId: null,
  paymentStatusId: null,
  comment: '',
  isWriteOnTop: false,
}, action) {
  switch (action.type) {
    case TypeConst.CLEAR_BY_REQUEST:
    case TypeConst.CLEAR:
      return {
          ...state,
          isOpen: false,
          bookingId: null,
          date: DateLib.formatterDate(new Date()),
          minutes: 0,
          slotTimeId: null,
          typeId: null,
          userId: (action.type === TypeConst.CLEAR_BY_REQUEST) ? state.userId : null,
          userBalanceId: null,
          orderId: null,
          productId: null,
          paymentTypeId: null,
          paymentStatusId: null,
          comment: '',
          isWriteOnTop: false,
      };
    case TypeConst.SET_IS_OPEN:
      return {
          ...state,
          isOpen: action.isOpen,
      };
    case TypeConst.SET_IS_LOAD_UPDATE:
      return {
          ...state,
          isLoadUpdate: action.isLoadUpdate,
      };
    case TypeConst.SET_BOOKING_ID:
      return {
          ...state,
          bookingId: action.bookingId,
      };
    case TypeConst.SET_DATE:
      return {
          ...state,
          date: DateLib.formatterDate(action.date),
      };
    case TypeConst.SET_MINUTES:
      return {
          ...state,
          minutes: action.minutes,
      };
    case TypeConst.SET_SLOT_TIME_ID:
      return {
          ...state,
          slotTimeId: action.slotTimeId,
      };
    case TypeConst.SET_TYPE_ID:
      return {
          ...state,
          typeId: action.typeId,
      };
    case TypeConst.SET_USER_ID:
      return {
          ...state,
          userId: action.userId,
      };
    case TypeConst.SET_USER_BALANCE_ID:
      return {
          ...state,
          userBalanceId: action.userBalanceId,
      };
    case TypeConst.SET_ORDER_ID:
      return {
          ...state,
          orderId: action.orderId,
      };
    case TypeConst.SET_PRODUCT_ID:
      return {
          ...state,
          productId: action.productId,
      };
    case TypeConst.SET_PAYMENT_TYPE_ID:
      return {
          ...state,
          paymentTypeId: action.paymentTypeId,
      };
    case TypeConst.SET_PAYMENT_STATUS_ID:
      return {
          ...state,
          paymentStatusId: action.paymentStatusId,
      };
    case TypeConst.SET_COMMENT:
      return {
          ...state,
          comment: action.comment,
      };
    case TypeConst.SET_IS_WRITE_ON_TOP:
      return {
          ...state,
          isWriteOnTop: action.isWriteOnTop,
      };
    default:
        return state;
  }
}
