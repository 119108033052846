import { setIsOpen, setSlotTimeId, setDate, 
    setTypeId, setPaymentTypeId, setPaymentStatusId,
    setComment, setMinutes, setBookingId, setUserId, setOrderId, setUserBalanceId, setProductId,
} from "../../../reducers/BookingPanel/BookingPanelActions";
import { setDate as setDatePageBooking } from "../../../reducers/PageBooking/PageBookingActions";
import DateLib from "../../../inc/DateLib";
import store from "../../../reducers/store";

export default function openBookingPanel({
        dispatch, 
        date,
        slotTimeId = null,
        bookingId = null,
        minutes = 0,
        typeId = null,
        userId = null,
        userBalanceId = null,
        orderId = null,
        productId = null,
        paymentTypeId = null,
        paymentStatusId = null,
        comment = '',
    }){
    const storeList = store.getState().list;
    dispatch(setIsOpen(true));
    dispatch(setDate(DateLib.formatterDate(date)));
    dispatch(setDatePageBooking(DateLib.formatterDate(date)));
    dispatch(setSlotTimeId(slotTimeId));
    
    if(bookingId){
        dispatch(setBookingId(bookingId));
        dispatch(setMinutes(minutes));
        dispatch(setTypeId(typeId));
        dispatch(setUserId(userId));
        dispatch(setUserBalanceId(userBalanceId));
        dispatch(setOrderId(orderId));
        dispatch(setProductId(productId));
        dispatch(setPaymentTypeId(paymentTypeId));
        dispatch(setPaymentStatusId(paymentStatusId));
        dispatch(setComment((comment) ? comment : ''));
    }else{
        dispatch(setBookingId(null));
        if(minutes) dispatch(setMinutes(minutes));
        if(!typeId && storeList.bookingTypes) setDefaultTypeId({dispatch, bookingTypes: storeList.bookingTypes});
        if(userId) dispatch(setUserId(userId));
        //userBalanceId
        if(orderId) dispatch(setOrderId(orderId));
        //productId
        if(!paymentTypeId && storeList.paymentTypes) setDefaultPaymentTypeId({dispatch, paymentTypes: storeList.paymentTypes});
        if(!paymentStatusId && storeList.paymentStatuses) setDefaultPaymentStatusId({dispatch, paymentStatuses: storeList.paymentStatuses});
        if(comment) dispatch(setComment(comment));
    }
}

function setDefaultTypeId({dispatch, bookingTypes}){
    let defaultId = 0;
    Object.keys(bookingTypes).forEach((key)=>{
        let item = bookingTypes[key];
        if(item.slug === 'booking') defaultId = item.id;
    });
    dispatch(setTypeId(defaultId));
}
function setDefaultPaymentTypeId({dispatch, paymentTypes}){
    let defaultId = 0;
    Object.keys(paymentTypes).forEach((key)=>{
        let item = paymentTypes[key];
        if(item.slug === 'cash') defaultId = item.id;
    });
    dispatch(setPaymentTypeId(defaultId));
}
function setDefaultPaymentStatusId({dispatch, paymentStatuses}){
    let defaultId = 0;
    Object.keys(paymentStatuses).forEach((key)=>{
        let item = paymentStatuses[key];
        if(item.slug === 'create') defaultId = item.id;
    });
    dispatch(setPaymentStatusId(defaultId));
}