import TypeConst from "./ProfileConst";

export function setProfileRoles(roles){
    return {
        type: TypeConst.UPDATE_ROLES,
        roles: roles,
    }
}

export function setProfileFullName(fullName){
    return {
        type: TypeConst.UPDATE_FULLNAME,
        fullName: fullName,
    }
}