import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../__button";
import AccountCircle from '@mui/icons-material/AccountCircle';
import ModalUser from "../../users/__index/users__index-modal";
import ModalYesNo from "../../modal-yes-no/modal-yes-no";
import { setUserId } from "./../../../reducers/BookingPanel/BookingPanelActions";
import { updateUser } from "./../../../reducers/List/ListActions";

export default function BookingPanelUser({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const dataUserId = useSelector((state) => state.data.userId);
    const userId = useSelector((state) => state.bookingPanel.userId);
    const user = useSelector((state) => (userId && userId in state.list.users) ? state.list.users[userId] : null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalEditUserId, setIsOpenModalEditUserId] = useState(false);
    const openModal = () =>{ setIsOpenModal(true)}
    const closeModal = () =>{ setIsOpenModal(false)}
    const actionSelectUser = (user) => {
        dispatch(updateUser(user.id, user));
        dispatch(setUserId(user.id));
        closeModal();
    }
    useEffect(()=>{
        if(isEdit && dataUserId){
            if(!userId) dispatch(setUserId(dataUserId));
            else if(userId && userId !== dataUserId) setIsOpenModalEditUserId(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dataUserId]);
    return (<>
            <Button 
                {...other}
                Icon={AccountCircle}
                onClick={(!isEdit)? null : openModal}
            >
                {(user) ? user.surnameNM : 'Пользователь'}
            </Button>
            <ModalUser
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                actionSelectUser={actionSelectUser}
                columns={{showBalances: false}}
            />
            <ModalYesNo
                title="Изменить пользователя в бронировании?"
                isOpenModal={isOpenModalEditUserId}
                closeModal={()=>setIsOpenModalEditUserId(false)}
                actionBtnNo={()=>setIsOpenModalEditUserId(false)}
                actionBtnYes={()=>{
                    dispatch(setUserId(dataUserId));
                    setIsOpenModalEditUserId(false);
                }}
            />
        </>
    );
}