// import { formatterUser } from "./User";
// import formatterBookingUserBalance from "./BookingUserBalance";
import { formatterBookingTime } from "./BookingTime";
// import { formatterBookingType } from "./BookingType";
// import { formatterOrderCertificate, formatterOrder } from "./Order";
export function formatterBooking(data){
    //let user = (data.user) ? data.user : null;
    return {
        id: parseInt(data.id),
        time: (data.time) ? formatterBookingTime(data.time) : null,
        //userBalance:  (data.time) ?  formatterBookingUserBalance(data.time.user_balance) : null,
        //user: user ? formatterUser(user) : null,
        //certificate: (data.order && data.order.certificate) ? formatterOrderCertificate(data.order.certificate) : null,
        //order: (data.order) ? formatterOrder(data.order) : null,
        //type: formatterBookingType(data.type),
        userId: parseInt(data.user_id),
        typeId: parseInt(data.type_id),
        statusId: parseInt(data.status_id),
        orderId: (data.order_id) ? parseInt(data.order_id) : null,
        minutes: data.minutes,
        slotTimeId: (data.slotTimeKeys && (
            data.slotTimeKeys.id || data.slotTimeKeys.id === 0
            )) ? parseInt(data.slotTimeKeys.id) : null,
        slotTimeDate: (data.slotTimeKeys && data.slotTimeKeys.date) ? new Date(data.slotTimeKeys.date) : null,
        slotTimeDateTimeStart: (data.slotTimeKeys && data.slotTimeKeys.date_start) ? new Date(data.slotTimeKeys.date_start) : null,
        slotTimeDateTimeEnd: (data.slotTimeKeys && data.slotTimeKeys.date_end) ? new Date(data.slotTimeKeys.date_end) : null,
        comment: data.comment,
        createdAt: new Date(data.created_at),
        updatedAt: new Date(data.updated_at),
    }
}
export function formatterBookingErrors(data){
    let errorValids = {};
    let valids = {
        'bookingData.minutes': 'minutes',
        'slotTime.timeId': 'slotTimeId',
        'bookingData.order_id': 'orderId',
        'bookingData.user_id': 'userId',
        'time.balance_user_id': 'balanceUserId',
        'order.product_id': 'productId',
    };
    Object.keys(data).forEach((key)=>{
        let item = data[key];
        if(key in valids) errorValids[valids[key]] = item;
    })
    return {
        valids: errorValids,
    };
}