import config from '../config.json';

const CONFIG = {
    serverUrl: config.serverUrl,
    isDebug:  (config.isDebug) ? config.isDebug : false,
    release:  (config.release) ? config.release : 'prod',
    _user:{
        email: (config._user && config._user.email) ? config._user.email : null,
        password: (config._user && config._user.password) ? config._user.password : null,
    },
    title: "FlyStation Booking System",
    pusher: {
        key: config.pusher.key,
        cluster: (config.pusher.cluster) ? config.pusher.cluster : 'eu',
    },
}
export default CONFIG;
