const btnStyle = {
    width: "93%",
    margin: '5px',
    fontSize: "16px"
 };

const style = {
    wrapper: {
        width: "100%",
        marginBottom: "10px",
        flex: "0 0 auto",
    },
    btnSave: {
        ...btnStyle,
        margin: "10px",
    },
    btnCreate: {
        ...btnStyle
    },
    btnCancel: {
        ...btnStyle,
        height: "2.75rem",
        width: "45%"
    },
    btnCancelAndCopy: {
        ...btnStyle,
        width: "45%",
        height: "2.75rem",
        fontSize: "10px"
    },
}
export default style;