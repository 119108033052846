import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputNumberPlusMinus from "../../inputs/number-plus-minus";

import { setMinutes } from "./../../../reducers/BookingPanel/BookingPanelActions";


const listMinutes = [2, 4, 6, 10, 15, 30];
export default function BookingPanelMinutes({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const minutes = useSelector((state) => state.bookingPanel.minutes);
    const actionEdit = (value) => {
        value = parseInt(value);
        if(value >= 0 && isEdit)
            dispatch(setMinutes(value));
    }
    return <InputNumberPlusMinus
        {...other}
        label="Минут"
        value={minutes}
        list={listMinutes}
        setValue={actionEdit}
    />
}