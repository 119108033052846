import React from "react";
import { useSelector } from "react-redux";

import Type from "../__type";
import PaymentTypes from "../__payment-type";
import PaymentStatuses from "../__payment-status";

import "./styles.css";

export default function BookingPanelContentRight({type, valids = {} }){
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);
    return <div className="booking-panel__content-right">
        <Type errors={valids.typeId} isEdit={!bookingId}/>
        {(type.slug === "booking")?
            <>
                <PaymentTypes errors={valids.paymentTypeId}/>
                <PaymentStatuses errors={valids.paymentStatusId}/>
            </>
        : null}
    </div>
}