import React  from "react";
import { useDispatch, useSelector } from "react-redux";

import InputSelect from "../__select";
import DateLib from "../../../inc/DateLib";
import { setSlotTimeId } from "./../../../reducers/BookingPanel/BookingPanelActions";

import style from "./style";

export default function BookingPanelTime({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const slotTimeId = useSelector((state) => state.bookingPanel.slotTimeId);
    const date = useSelector((state) => state.pageBooking.date);
    const slotTimes = useSelector((state) => { return (date in state.pageBooking.slotTimes) ? state.pageBooking.slotTimes[date] : []});
    let items = [];
    Object.keys(slotTimes).forEach((key)=>{
        let item = slotTimes[key];
        items[key] = {
            onClick: (!isEdit)? null : () =>{
                dispatch(setSlotTimeId(parseInt(key)));
            },
            label: DateLib.formatterTime(item.dateStart),
        }
    });
    return <InputSelect
        {...other}
        label="Время"
        sx={style.input}
        value={slotTimeId}
        items={items}
        id="time"
        labelId="time"
    />
}

