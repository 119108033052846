import DateLib from "../../inc/DateLib";

const countLeftDay = 1;
const countRightDay = 1;
const countDayForLazyLoad = 0;

export function getDates(date, isLazyLoad = false){
    let _date = new Date(date);
    let leftDate = getLeftDate(_date, isLazyLoad);
    let rightDate = getRightDate(_date, isLazyLoad);
    let dates = [];
    let iDate = leftDate;
    while(iDate.getTime() <= rightDate.getTime()){
        dates.push(DateLib.formatterDate(iDate));
        iDate = new Date(iDate.getTime() + DateLib.day)
    }
    return dates;
}
export function getDatesByDays(date){
    let dates = getDates(date, true);
    return [
        {
            days: dates.length,
            date: dates[0],
        }
    ];
}
function getLeftDate(date, isLazyLoad = false){
    return new Date(date.getTime() - DateLib.day*(countLeftDay + (isLazyLoad ? countDayForLazyLoad : 0)));
}
function getRightDate(date, isLazyLoad = false){
    return new Date(date.getTime() + DateLib.day*(countRightDay + (isLazyLoad ? countDayForLazyLoad : 0)));
}
export function checkIsNeighbour(date, dateCheck){
    if(DateLib.equals(dateCheck, new Date(date.getTime() - DateLib.day)))
        return true;
    else if(DateLib.equals(dateCheck, new Date(date.getTime() + DateLib.day)))
        return true;
    return false;
}