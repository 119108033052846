import TypeConst from "./ProfileConst";

export default function todosReducer(state = {
    roles: [],
}, action) {
    switch (action.type) {
        case TypeConst.UPDATE_ROLES:
            return {
                ...state,
                roles: action.roles
            };
        case TypeConst.UPDATE_FULLNAME:
            return {
                ...state,
                fullName: action.fullName
            };
        default:
            return state;
    }
}
