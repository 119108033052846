import { loadSlotTime } from "./Booking";
import { loadUsers, loadOrders, loadProducts } from "./List";
import store from "../reducers/store";

export async function loadDataSlotTime({dispatch, date, dates, days}){
    let data = await loadSlotTime({dispatch, date, dates, days});
    if(!data) return null;
    let userIds = [];
    let orderIds = [];
    const storeList = store.getState().list
    Object.keys(data).forEach(date => {
        let item = data[date];
        Object.keys(item).forEach(timeId => {
            let slotTime = item[timeId];
            slotTime.bookings.forEach((booking)=>{
                if(booking.userId && !userIds.includes(booking.userId) && !(booking.userId in storeList.users))
                    userIds.push(booking.userId);
                if(booking.orderId && !orderIds.includes(booking.orderId) && !(booking.orderId in storeList.orders))
                    orderIds.push(booking.orderId);
            });
        });
    });
    
    if(userIds.length) loadDataUsers({dispatch, ids: userIds});
    if(orderIds.length) loadDataOrders({dispatch, ids: orderIds});
    return true;
    
}
export async function loadConnectionsForBookings({dispatch, bookings}){
    let userIds = [];
    let orderIds = [];
    const storeList = store.getState().list
    bookings.forEach((booking)=>{
        if(booking.userId && !userIds.includes(booking.userId) && !(booking.userId in storeList.users))
            userIds.push(booking.userId);
        if(booking.orderId && !orderIds.includes(booking.orderId) && !(booking.orderId in storeList.orders))
            orderIds.push(booking.orderId);
    })
    if(userIds.length) loadDataUsers({dispatch, ids: userIds});
    if(orderIds.length) loadDataOrders({dispatch, ids: orderIds});
    return true;
}
export async function loadDataUsers({dispatch, ids}){
    let data = await loadUsers({dispatch, ids});
    if(!data) return null;
    return true;
}
export async function loadDataOrders({dispatch, ids}){
    let data = await loadOrders({dispatch, ids});
    if(!data) return null;
    let productIds = [];
    const storeList = store.getState().list
    data.forEach(order => {
        if(order.productId && !productIds.includes(order.productId) && !(order.productId in storeList.products))
            productIds.push(order.productId);
    });
    if(productIds.length) loadProducts({dispatch, ids: productIds});
    return true;
}