import TypeConst from "./PageBookingConst";

export default function todosReducer(state = { 
  isSearch: false,
  date: null,
  leftDate: null,
  rightDate: null,
  slotTimes: {},
}, action) {
switch (action.type) {
  case TypeConst.SET_IS_SEARCH:
    return {
        ...state,
        isSearch: action.isSearch
    };
  case TypeConst.SET_DATE:
    return {
        ...state,
        date: action.date
    };
  case TypeConst.UPDATE_SLOT_TIMES:
    let dates = {};
    Object.keys(action.slotTimes).forEach((date) => {
      let data = action.slotTimes[date];
      dates[date] = {...state.slotTimes[date], ...data}
    });
    return {
        ...state,
        slotTimes: {...state.slotTimes, ...dates},
    };
  default:
    return state;
  }
}
