import ApiInitByAuth from "./ApiInitByAuth";;

export default class ApiModel extends ApiInitByAuth{
    __data = null;
    __code = null;
    errors = null;
    __type = "GET";
    __responseIsArray = false;
    isCheckPagination = false;
    paths = {};
    pathDef = null;
    formatterResponseData = (rdata) => rdata;
    formatterItem = (item) => item;
    formatterErrors = (errors) => errors;
    __path = null;
    __params = null;

    paginationCurrent = null;
    paginationNext = null;
    paginationLast = null;

    async getResponse(customParams = {}){
        let rdata = null;
        this.errors = null;
        if(!this.__path) return null;

        if(this.__type === "GET"){
            let params = this.pasreParamsForGet({...this.__params, ...customParams});
            rdata = await this._query(`${this.__path}?${params}`, "GET");
        }else if(this.__type === "POST" || this.__type === "PATCH")
            rdata = await this._query(this.__path, this.__type, this.__params);
        if(!rdata) return null;
        if(!rdata.result){
            if(rdata.error.messages) this.setErrors(rdata.error.messages);
            return null;
        }
        if(this.isCheckPagination)
            this.checkPagination(rdata);
        let data = this.formatterResponseData(rdata);
        if(this.__responseIsArray){
            return data.map((item)=>{
                return this.formatterItem(item);
            });
        }
        return this.formatterItem(data);
    }
    async getResponseNext(){
        let customParams = {};
        if(this.paginationNext) customParams.page = this.paginationNext;
        return await this.getResponse(customParams);
    }

    setErrors(errors){
        this.errors = this.formatterError(errors);
    }
    static Index(params){
        const api = new this({});
        api.index(params);
        return api;
    }
    static Store(params){
        const api = new this({});
        api.store(params);
        return api;
    }
    static Show(id){
        const api = new this({});
        api.show(id);
        return api;
    }
    static Update(id, params){
        const api = new this({});
        api.update(id, params);
        return api;
    }


    index(params){
        this.__responseIsArray = true;
        this.isCheckPagination = true;
        this.__params = params;
        //this.__params = this.pasreParamsForGet(params);
        this.__path = ('index' in this.paths) ? this.paths.index : this.pathDef;
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data.data;
        }
    }
    store(params){
        this.__type = "POST";
        this.__path = ('store' in this.paths) ? this.paths.update : this.pathDef;
        this.__params = this.getParamsForStore(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    show(id){
        this.__path = ('show' in this.paths) ? this.paths.show+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    update(id, params){
        this.__type = "PATCH";
        this.__path = ('update' in this.paths) ? this.paths.update+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        this.__params = this.getParamsForUpdate(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    getParamsForStore(params){}
    getParamsForUpdate(params){}


    
    checkPagination(rdata){
        let response = rdata.response ? rdata.response : null; 
        if(response && response.data && response.data.current_page && response.data.last_page){
            this.paginationCurrent = response.data.current_page ? parseInt(response.data.current_page) : null;
            this.paginationLast = parseInt(response.data.last_page);
            if(this.paginationCurrent && this.paginationCurrent < this.paginationLast) 
                this.paginationNext = this.paginationCurrent + 1;
            else this.paginationNext = null;
        }else this.paginationNext = null;
        
    }
}
