export function formatterProduct(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        minutes: data.minutes,
        price: data.price,
        category: (data.category) ? formatterProductCategory(data.category): null,
    }
}
export function formatterProductCategory(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        slug: data.slug,
    }
}