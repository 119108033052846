import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputSelect from "../__select";
import { setTypeId } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelType({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const bookingTypes = useSelector((state) => state.list.bookingTypes);
    const typeId = useSelector((state) => state.bookingPanel.typeId);
    let items = [];
    Object.keys(bookingTypes).forEach((key)=>{
        let item = bookingTypes[key];
        items[key] = {
            onClick: (!isEdit)? null : () =>{
                dispatch(setTypeId(item.id));
            },
            label: item.name,
        }
    });
    return <InputSelect
        {...other}
        label="Тип"
        value={typeId}
        items={items}
    />
}