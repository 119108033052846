import React from "react";

import Select from "../../input/__select";

import style from "./style";
import "./styles.css";

export default function BookingPanelSelect({...other}){
    return <Select
        className="booking-panel__select"
        sxFormControl={style.selectFormControl}
        sx={style.select}
        {...other}
    />
}