const style = {
    input: {
        width: '30%',
        margin: '10px'
    },
    table: {
        
    },
    tableContainer:{
        height: '300px',
        overflowX: 'scroll',
    },
    btnCreate:{
        position: "absolute",
        right: "20px",
        width: "30px",
        height: "30px",
    }
};
export default style;