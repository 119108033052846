import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreateIcon from '@mui/icons-material/Create';
import InputText from '../../input/__text';
import InputPhone from "../../input/__phone";
import InputEmail from "../../input/__email";
import ModalList from '../../modal-list';
import ModalUserEdit from '../__edit/users__edit-modal';
import ModalUserCreate from '../__create/users__create-modal';
import ModalUserBalance from "../../user-balances/__index/user-balances__index-modal";
import BookingsExport from "../../bookings/__export";
import UserBalancesExport from "../../user-balances/__export";
import { updateUser } from "../../../reducers/List/ListActions";

import ApiUsers from "../../../api/Admin/Users";
import style from "./style-modal";


const arrColumns = ["surname", "name", "middleName", "email", "phone", "edit", "showBalances", "exportBookings", "exportUserBalances",];

const filterDataByColumns = (columns, params) =>{
    let data = [];
    arrColumns.forEach((key)=>{
        if(key in columns && key in params && columns[key]) data.push(params[key]);
    });
    return data;
}

export default function UsersIndexModal({isOpenModal, actionSelectUser, columns, ...other}){
    const dispatch = useDispatch();
    columns = {
        name: true, surname: true, middleName: true, 
        email: true, phone: true, edit:true, 
        showBalances: true, exportBookings: true, exportUserBalances: true, 
        ...columns
    };
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('7');

    const [isCreateUser, setIsCreateUser] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [showUserBalances, setShowUserBalances] = useState(null);

    const loadUsers = async ({name, surname, middleName, email, phone}) => {
        let params = {};
        if(phone.length > 2) params.phone = phone;
        
        const apiUsers = ApiUsers.Index({name, surname, middleName, email, ...params});
        const data = await apiUsers.getResponse();
        if(data) setUsers(data);
    }

    useEffect(()=>{
        if(
            isOpenModal && (
                name.length > 2
                || surname.length > 2
                || middleName.length > 2
                || email.length > 2
                || phone.length > 2
            )
        ) loadUsers({name, surname, middleName, email, phone});
        else setUsers([]);
    }, [name, surname, middleName, email, phone, isOpenModal]);

    const actionEditName = (e) => {setName(e.target.value)}
    const actionEditSurName = (e) => {setSurname(e.target.value)}
    const actionEditMiddleName = (e) => {setMiddleName(e.target.value)}
    const actionEditEmail = (e) => {setEmail(e.target.value)}
    const actionEditPhone = (e) => {
        let value = e.target.value;
        if(value.length >= 1 && value.length <= 11) setPhone(value)
    }
    const actionClear = () => {
        setName('');
        setSurname('');
        setMiddleName('');
        setEmail('');
        setPhone('7');
    }
    const actionReLoad = (data) =>{
        loadUsers({name, surname, middleName, email, phone});
    }
    const actionSuccessCreate = (user) =>{
        if(actionSelectUser) actionSelectUser(user);
        actionReLoad();
        setIsCreateUser(false);
    }
    const actionEditUser = (user) => {
        dispatch(updateUser(user.id, user));
        setEditUser(user.id);
    }
    const actionShowUserBalances = (user) => {
        dispatch(updateUser(user.id, user));
        setShowUserBalances(user.id);
    }
    return <> 
    <ModalList
        {...other}
        isOpenModal={isOpenModal}
        id="users"
        title="Пользователи"
        headers={filterDataByColumns(columns, {
            surname: "Фамилия",
            name: "Имя",
            middleName: "Отчество",
            email: "E-mail",
            phone: "Телефон",
            edit: "",
            showBalances: "",
            exportBookings: "",
            exportUserBalances: "",
        })}
        list={users.map((user)=>{
            return {
                click: (actionSelectUser) ? () => {
                    actionSelectUser(user);
                }: null,
                columns: filterDataByColumns(columns, {
                    surname: user.surname,
                    name: user.name,
                    middleName: user.middleName,
                    email: user.email,
                    phone: user.phone,
                    edit: <CreateIcon onClick={()=> actionEditUser(user)}/>,
                    showBalances: <AccountBalanceIcon onClick={()=> actionShowUserBalances(user)}/>,
                    exportBookings: <BookingsExport 
                        params={{userId: user.id}}
                    />,
                    exportUserBalances: <UserBalancesExport 
                        params={{userId: user.id}}
                    />,
                })
            }
        })}
        actionClear={actionClear}
        actionCreate={() => setIsCreateUser(true)}
    >
        <InputText
            label="Фамилия"
            variant="standard"
            value={surname}
            onChange={actionEditSurName}
            sx={style.input}
        />
        <InputText
            label="Имя"
            variant="standard"
            value={name}
            onChange={actionEditName}
            sx={style.input}
        />
        <InputText
            label="Отчество"
            variant="standard"
            value={middleName}
            onChange={actionEditMiddleName}
            sx={style.input}
        />
        <InputEmail
            label="Email"
            variant="standard"
            value={email}
            onChange={actionEditEmail}
            sx={style.input}
        />
        <InputPhone
            label="Телефон"
            variant="standard"
            value={phone}
            onChange={actionEditPhone}
            sx={style.input}
        />
    </ModalList>
        {(editUser) ?
        <ModalUserEdit
            isOpenModal={(editUser) ? true : false}
            closeModal={() => setEditUser(null)}
            userId={editUser}
            actionSuccessUpdate={() =>{
                actionReLoad();
                setEditUser(null);
            }}
        /> : null}
        {(isCreateUser) ?
        <ModalUserCreate
            isOpenModal={isCreateUser}
            closeModal={() => setIsCreateUser(false)}
            actionSuccessCreate={actionSuccessCreate}
        /> : null}

        {(showUserBalances) ?
        <ModalUserBalance
            isOpenModal={(showUserBalances) ? true : false}
            closeModal={()=> setShowUserBalances(null)}
            userId={showUserBalances}
            actionSuccessCreate={() =>{actionReLoad()}}
            actionSuccessUpdate={() =>{actionReLoad()}}
        />  : null}
    </>
}