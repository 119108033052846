import React from "react";

import TextField from "@mui/material/TextField";

export default function InputText({errors, ...other}){
    return <TextField
        {...other}
        error={(errors) ? true : false}
        helperText={errors}
    />
}