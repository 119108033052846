import React from "react";
import { useDispatch, useSelector, batch } from "react-redux";

import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { setDate as setDatePageBooking } from "../../reducers/PageBooking/PageBookingActions";
import { setDate as setDateBookingPanel } from "../../reducers/BookingPanel/BookingPanelActions";
import DateLib from "../../inc/DateLib";
import CONFIG from "../../inc/Config";
import './style.css';


export default function MenuBookingFooter(){
    const dispatch = useDispatch();
    const date = useSelector((state) => state.pageBooking.date);
    const setDate = (date) =>{
        date = DateLib.formatterDate(date);
        batch(()=>{
            dispatch(setDatePageBooking(date));
            dispatch(setDateBookingPanel(date));
        });
    }
    const actionPrevDay = () =>{
        let _date = new Date(date);
        setDate(new Date(_date.getTime() - DateLib.day))
    }
    const actionNextDay = () =>{
        let _date = new Date(date);
        setDate(new Date(_date.getTime() + DateLib.day))
    }
    return <div className="booking__footer">
        {date ?
        <>
            <IconButton color="primary" aria-label="prev day" component="label" onClick={actionPrevDay}>
                <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton color="primary" aria-label="next day" component="label" onClick={actionNextDay}>
                <KeyboardDoubleArrowRightIcon />
            </IconButton>
        </>
        : null }
        {(CONFIG.release === 'beta') ? <div className="booking__footer-release">{CONFIG.release}</div>: null}
    </div>
}