import React, { useLayoutEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './App.css';
import PageBooking from './Pages/Booking';
import PageLogin from './Pages/Login';
import LoaderApp from "./components/loader-app";
import { initDataLogin } from "./inc/lib";
import DateLib from "./inc/DateLib";

const _date = DateLib.formatterDate(new Date());

function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.app.isAuth);
  useLayoutEffect(() => {
    if(!isAuth) initDataLogin({dispatch});
  }, [dispatch, isAuth]);
  return <>
  <LoaderApp/>
  {(isAuth) ? <>
    <BrowserRouter>
      <Routes>
        <Route
          path="date/:date"
          element={<PageBooking />}
        />
        <Route path="*" element={<Navigate to={`date/${_date}`} />}/>
      </Routes>
    </BrowserRouter>
  </>: <PageLogin/>}
  </>;
}

export default App;
