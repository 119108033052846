import React from "react";

import FormControl from '@mui/material/FormControl';
import MessageErrors from "../../message/__errors";
import styleError from "../../message/__errors/style";

import "./styles.css";

export default function InputNumberPlusMinus({setValue, label, value, list = [], errors,}){
    const actionPlus = () => {setValue(value + 1);}
    const actionMinus = () => {setValue(value - 1);}
    const actionSetFromList = (value) => {setValue(value);}
    const actionSet = (e) => {setValue(e.target.value);}
    let styleButton = (errors) ? styleError.button : {};

    return <FormControl error={(errors) ? true : false}>
        <div className="input-number-plus-minus">
            <p className="input-number-plus-minus__label">{label}</p>
            <div className="input-number-plus-minus__wrapper">
                <div className="input-number-plus-minus__input">
                    <button
                        className="input-number-plus-minus-input__plus"
                        onClick={actionPlus}
                    >+</button>
                    <input
                        className="input-number-plus-minus-input__value"
                        name="minute"
                        type="text"
                        value={value}
                        onChange={actionSet}
                        style={styleButton}
                    />
                    <button
                        className="input-number-plus-minus-input__minus"
                        onClick={actionMinus}
                    >-</button>
                    <MessageErrors errors={errors}/>
                </div>
                <div className="input-number-plus-minus__list">
                    {list.map((item) =>{
                        return <button
                            key={item}
                            className="input-number-plus-minus__list-item"
                            onClick={()=>{actionSetFromList(item)}}>
                                {item}
                        </button>
                    })}
                </div>
            </div>
        </div>
    </FormControl>
}