import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import ModalYesNo from "../../modal-yes-no";
import ModalMessage from "../../modal-message";
import MessageErrors from "../../message/__errors";
import ApiBooking from "../../../api/ApiBooking";

import './styles.css';

const apiBooking = new ApiBooking({});
const styles = {
    icon: {
        padding: "0 2px 0 0",
        height: 20,
        width: "auto",
    }
}
export default function BookingRowTookPlace({booking}){
    const [modalTookPlace, setModalTookPlace] = useState(false);
    const [valids, setValids] = useState({});
    const status = useSelector((state) => 
        (booking.statusId in state.list.bookingStatuses) 
            ? state.list.bookingStatuses[booking.statusId] : null, (a, b) => (a && b && a.id === b.id));
    const now = new Date();
    if(booking.slotTimeDateTimeEnd.getTime() > now.getTime()) return null;
    if(!status || (
        status.slug !== 'done'
        && status.slug !== 'took-place'
        && status.slug !== 'not-took-place'
        )) return null;
    const actionTookPlace = async () =>{
        let res = await apiBooking.tookPlaceBooking({id: booking.id});
        if(res){
            setModalTookPlace(false);
        }else{
            setValids(apiBooking.errors);
        }
    }
    const actionDenyStatusChange = async () => {
        let res = await apiBooking.processBooking({id: booking.id});
        if(res){
            setModalTookPlace(false);
        }else{
            setValids(apiBooking.errors);
        }
    }
    const actionNotTookPlace = async () =>{
        let res = await apiBooking.notTookPlaceBooking({id: booking.id});
        if(res){
            setModalTookPlace(false);
        }else{
            setValids(apiBooking.errors);
        }
    }
    if(status.slug === 'done')
        return <div className="bookings__row-took-place">
            <IconButton 
                disabled={(status.slug === 'not-took-place')} 
                color="secondary" 
                onClick={() => setModalTookPlace(true)}
                sx={styles.icon}
            >
                <CloseFullscreenIcon />
            </IconButton>
            <ModalYesNo
                title="Бронирование состоялось?"
                isOpenModal={modalTookPlace}
                closeModal={()=>setModalTookPlace(false)}
                actionBtnYes={actionTookPlace}
                actionBtnNo={actionNotTookPlace}
            />
            <ModalMessage
                isOpenModal={Object.keys(valids).length > 0}
                closeModal={() => setValids({})}
            >
                <MessageErrors errors={valids}/>
            </ModalMessage>
        </div>
    else return <div>
        {(status.slug === 'took-place')?
        <div className="bookings__row-took-place">
        <IconButton 
            color="success" 
            onClick={() => setModalTookPlace(true)}
            sx={styles.icon}
        >
            <DoneIcon />
        </IconButton>
        <ModalYesNo
            title="Вернуться к режиму редактирования?"
            isOpenModal={modalTookPlace}
            closeModal={()=>setModalTookPlace(false)}
            actionBtnYes={actionDenyStatusChange}
            actionBtnNo={() => setModalTookPlace(false)}
        />
        <ModalMessage
            isOpenModal={Object.keys(valids).length > 0}
            closeModal={() => setValids({})}
        >
            <MessageErrors errors={valids}/>
        </ModalMessage>
    </div>
        :
        <IconButton 
            color="error" 
            onClick={() => setModalTookPlace(true)}
            sx={styles.icon}
        >
            <CloseIcon />
        </IconButton>
        }
    </div>
}

