import ApiModel from "../ApiModel";
import { formatterUser, formatterUserErrors} from "../Formatter/User";

export default class ApiUsers extends ApiModel{
    formatterItem = formatterUser;
    formatterError = formatterUserErrors;
    pathDef = 'admin/users';
    
    getParamsForStore({name, surname, middleName, email, phone}){
        let data = {};
        if(name) data.name = name;
        if(surname) data.surname = surname;
        if(middleName) data.middle_name = middleName;
        if(email) data.email = email;
        if(phone) data.phone = phone;
        return data;
    }
    getParamsForUpdate({name, surname, middleName, email, phone}){
        let data = {};
        if(name) data.name = name;
        if(surname) data.surname = surname;
        if(middleName) data.middle_name = middleName;
        if(email) data.email = email;
        data.phone = (phone && phone.length > 5) ? phone : null;
        return data;
    }
}
