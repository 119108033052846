import TypeConst from "./BookingPanelConst";

export function clear(){
    return {
        type: TypeConst.CLEAR,
    }
}
export function clearByRequest(){
    return {
        type: TypeConst.CLEAR_BY_REQUEST,
    }
}
export function setIsOpen(isOpen){
    return {
        type: TypeConst.SET_IS_OPEN,
        isOpen: isOpen,
    }
}
export function setIsLoadUpdate(isLoadUpdate){
    return {
        type: TypeConst.SET_IS_LOAD_UPDATE,
        isLoadUpdate: isLoadUpdate,
    }
}
export function setBookingId(bookingId){
    return {
        type: TypeConst.SET_BOOKING_ID,
        bookingId: bookingId,
    }
}
export function setDate(date){
    return {
        type: TypeConst.SET_DATE,
        date: date,
    }
}
export function setMinutes(minutes){
    return {
        type: TypeConst.SET_MINUTES,
        minutes: minutes,
    }
}
export function setSlotTimeId(slotTimeId){
    return {
        type: TypeConst.SET_SLOT_TIME_ID,
        slotTimeId: slotTimeId,
    }
}
export function setTypeId(typeId){
    return {
        type: TypeConst.SET_TYPE_ID,
        typeId: typeId,
    }
}
export function setUserId(userId){
    return {
        type: TypeConst.SET_USER_ID,
        userId: userId,
    }
}
export function setUserBalanceId(userBalanceId){
    return {
        type: TypeConst.SET_USER_BALANCE_ID,
        userBalanceId: userBalanceId,
    }
}
export function setOrderId(orderId){
    return {
        type: TypeConst.SET_ORDER_ID,
        orderId: orderId,
    }
}
export function setProductId(productId){
    return {
        type: TypeConst.SET_PRODUCT_ID,
        productId: productId,
    }
}
export function setPaymentTypeId(paymentTypeId){
    return {
        type: TypeConst.SET_PAYMENT_TYPE_ID,
        paymentTypeId: paymentTypeId,
    }
}
export function setPaymentStatusId(paymentStatusId){
    return {
        type: TypeConst.SET_PAYMENT_STATUS_ID,
        paymentStatusId: paymentStatusId,
    }
}
export function setComment(comment){
    return {
        type: TypeConst.SET_COMMENT,
        comment: comment,
    }
}
export function setIsWriteOnTop(isWriteOnTop){
    return {
        type: TypeConst.SET_IS_WRITE_ON_TOP,
        isWriteOnTop: isWriteOnTop,
    }
}