import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from "../../button";
import ModalYesNo from "../../modal-yes-no/modal-yes-no";
import User from "../__user";

import ApiAuth from "../../../api/ApiAuth";
import { setDataLogout } from "../../../inc/lib";
import { setIsSearch } from "../../../reducers/PageBooking/PageBookingActions";

import "./styles.css";


const apiAuth = new ApiAuth({});

function formatFullNameForButton(fullName = "EXIT") {
    const names = fullName.trim().split(" ");
    return (names.length === 1)
        ? names[0].length <= 5
            ? names[0]
            : names[0].slice(0, 3) + "..."
        : names.map( name => name.charAt(0) ).join('')
}

export default function BookingHeaderRight(){
    const dispatch = useDispatch();
    const roles    = useSelector((state) => state.profile.roles);
    const fullName = useSelector((state) => state.profile.fullName);
    const isSearch = useSelector((state) => state.pageBooking.isSearch);
    const [modalExit, setModalExit] = useState(false);
    const actionExit = async () => {
        console.log("Exit");
        const data = await apiAuth.logout();
        if(data) setDataLogout({dispatch});
    }
    return <>
        <div className="booking-header__right">

            <FormControlLabel
                id='FindBookingForm'
                control={
                    <Switch
                        checked={isSearch}
                        onChange={()=>dispatch(setIsSearch(!isSearch))}
                    />
                }
                label="Найти"
            />
            { roles.includes('admin')
                ?   <User/>
                :   null
            }
            <Button onClick={()=>setModalExit(true)}>
                {formatFullNameForButton(fullName)}
            </Button>
        </div>
        <ModalYesNo
            title="Вы действительно хотите выйти?"
            isOpenModal={modalExit}
            closeModal={()=>setModalExit(false)}
            actionBtnYes={()=>{
                actionExit();
                setModalExit(false);
            }}
            actionBtnNo={()=>setModalExit(false)}
        />
    </>
}