import { formatterBooking } from "./Booking";
import DateLib from "../../inc/DateLib";
export function formatterSlotTime(data){
    return {
        date: DateLib.formatterDate(new Date(data.date)),
        timeId: parseInt(data.timeId),
        slug: data.slug,
        isDayOff: data.isDayOff,
        dateStart: new Date(data.dateStart.date),
        dateEnd: new Date(data.dateEnd.date),
        interval: parseInt(data.interval),
        timeBooking: parseInt(data.timeBooking),
        timeFree: parseInt(data.timeFree),
        bookings: data.bookings.map((item) =>{
            return formatterBooking(item);
        })
    }
}
export function formatterSlotTimesToDates(data){
    let dates = {};
    data.forEach((item)=>{
        let slotTime = formatterSlotTime(item);
        if(!(slotTime.date in dates)) dates[slotTime.date] = {};
        dates[slotTime.date][slotTime.timeId] = slotTime;
    });
    return dates;
}
export function formatterTimeId(data){
    return {
        timeId: parseInt(data.timeId),
        interval: parseInt(data.interval),
        timeStart: data.timeStart,
        timeEnd: data.timeEnd,
    }
}