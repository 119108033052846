import React from "react";

import './styles.css';

export default function SlotTimesDate({date}){
    return <div className="slot-times-date">
        {(new Date(date)).toLocaleString("ru", {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        })}
    </div>
}