import React from "react";

import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Modal from "../modal";

import style from "./style";
import "./styles.css";


export default function ModalList({
    id = "",
    isOpenModal, 
    closeModal,
    title,
    headers = [],
    list = [],
    actionClear,
    actionCreate,
    children,
}){
    return (
        <Modal
            isOpenModal={isOpenModal}
            closeModal={closeModal}
        >
            <div className="modal-list__header">
                <Typography id={`modal-list-title__${id}`} variant="h6" component="h2">{title}</Typography>
                
                {(actionClear) ? 
                    <IconButton aria-label="Очистить" color="primary" onClick={actionClear}>
                        <DeleteIcon/>
                    </IconButton>
                : null }
                {(actionCreate) ? 
                    <IconButton aria-label="Создать" color="primary" sx={style.btnCreate} onClick={actionCreate}>
                        <AddIcon/>
                    </IconButton>
                : null }
            </div>
            <div>
                {children}
            </div>
            <TableContainer component={Paper}  sx={style.tableContainer}>
                <Table sx={style.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers.map((item, i) => <TableCell key={i}>{item}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {list.map((row, key) => (
                        <TableRow
                            key={key}
                            hover
                        >
                            {(row.columns) ? row.columns.map((item, i) => 
                                <TableCell key={i} onClick={
                                    (row.click && (typeof item == "string" || !item)) ? 
                                        () => row.click()
                                        : null
                                }>{item}</TableCell>) 
                            : ""}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    );
}