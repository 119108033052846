import CONFIG from "./Config";
import StorageLocal from "../storage/StorageLocal";
import { setToken } from "../reducers/App/AppActions";
import { setProfileRoles, setProfileFullName } from "../reducers/Profile/ProfileActions";

export function setTitle(title = ''){
    title = (title) ? `${title} | ${CONFIG.title}` : CONFIG.title;
    document.title = title;
}
export async function initDataLogin({dispatch}){
    let token = await StorageLocal.get('token');
    if(!token) return false;
    let roles = await StorageLocal.get('roles');
    let fullName = await StorageLocal.get('fullName');
    roles = JSON.parse(roles);
    dispatch(setToken(token));
    dispatch(setProfileRoles( (roles) ? roles : []));
    dispatch(setProfileFullName( (fullName) ? fullName : ""));
    return true;
}
export async function setDataLogin({dispatch, token, roles = [], fullName}){
    await StorageLocal.set('token', token);
    dispatch(setToken(token));
    if(roles && roles.length){
        await StorageLocal.set('roles', JSON.stringify(roles));
        dispatch(setProfileRoles(roles));
    }
    if(fullName && typeof fullName === 'string'){
        await StorageLocal.set('fullName', fullName);
        dispatch(setProfileFullName(fullName));
    }
}
export async function setDataLogout({dispatch}){
    await StorageLocal.remove('token');
    await StorageLocal.remove('roles');
    await StorageLocal.remove('fullName');
    dispatch(setToken(null));
    dispatch(setProfileRoles([]));
    dispatch(setProfileFullName(""));
}