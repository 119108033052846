import ApiInitByAuth from "./ApiInitByAuth";
import { formatterTimeId, formatterSlotTimesToDates } from "./Formatter/BookingSlotTime";
import { formatterBooking } from "./Formatter/Booking";
import { formatterBookingErrors } from "./Formatter/Booking";
import { formatterBookingType } from "./Formatter/BookingType";
import { formatterBookingStatus } from "./Formatter/BookingStatus";
import { formatterBookingTimeStatus } from "./Formatter/BookingTimeStatus";
import { formatterBookingTime, formatterBookingTimeErrors } from "./Formatter/BookingTime";
import formatterBookingUserBalanceType from "./Formatter/BookingUserBalanceType";


export default class ApiBooking extends ApiInitByAuth{
    async getSlotTimes(params = [], cb) {
        params.t = (new Date()).getTime();
        params.ver = 2;
        let _params = this.pasreParamsForGet(params);

        let rdata = await this._query(`admin/booking/slot-times?${_params}`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return formatterSlotTimesToDates(rdata.response.data);
    }
    async getTimeIds(params = {}, cb) {
        let rdata = await this._query(`admin/booking/slot-times/time-ids`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.map((item)=>{
            return formatterTimeId(item);
        });
    }
    async getBookings(params, cb){
        let _params = this.pasreParamsForGet(params);
        let rdata = await this._query(`admin/bookings?${_params}`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBooking(item);
        });
    }
    async getTypes(cb) {
        let rdata = await this._query(`admin/booking/types`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBookingType(item);
        });
    }
    async getStatuses(cb) {
        let rdata = await this._query(`admin/booking/statuses`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBookingStatus(item);
        });
    }
    async getTimeStatuses(cb) {
        let rdata = await this._query(`admin/booking/time/statuses`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBookingTimeStatus(item);
        });
    }
    async getBalanceTypes(cb) {
        let rdata = await this._query(`admin/booking/user/balance/types`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBookingUserBalanceType(item);
        });
    }
    async createUserBalance({userId, typeId},cb) {
        let rdata = await this._query(`admin/booking/user/balances`, "POST", {
            user_id: userId,
            type_id: typeId,
        });
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return true;
    }
    async createTime({balanceUserId, typeId, minutes, paymentTypeId = null, comment},cb) {
        let rdata = await this._query(`admin/booking/times`, "POST", {
            balance_user_id: balanceUserId,
            type_id: typeId,
            payment_type_id: paymentTypeId,
            minutes,
            comment: (comment) ? comment : null,
        });
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = formatterBookingTimeErrors(rdata.error.messages);
            return null;
        }
        return true;
    }

    async getBookingTimes(params, cb) {
        let _params = this.pasreParamsForGet(params);
        let rdata = await this._query(`admin/booking/times?${_params}`, "GET");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data.data.map((item)=>{
            return formatterBookingTime(item);
        });
    }





    async createBooking({ date, typeId, timeId, minutes, comment,
            userBalanceId, userId, isWriteOnTop,
            orderId, paymentTypeId, paymentStatusId, productId,
            type,
        }, cb) {
        let data = {
            'is_write_on_top': (isWriteOnTop) ? 1 : 0,
            'slotTime[date]': date,
            'slotTime[timeId]': timeId,
            'bookingData[minutes]': minutes,
            'bookingData[type_id]': typeId,
            'bookingData[user_id]': userId,
        }
        if(type.slug === 'balance')
            data['time[balance_user_id]'] = userBalanceId;
        else if(type.slug === 'certificate'){
            data['bookingData[order_id]'] = orderId;
        }
        else if(type.slug === 'booking'){
            data['order[payment_type_id]'] = paymentTypeId;
            data['order[product_id]'] = productId;
            data['order[payment_id]'] = paymentStatusId;
        }
        if(comment)
            data['bookingData[comment]'] = comment;
        let rdata = await this._query(`admin/bookings`, "POST", data , 'searchParams');
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = formatterBookingErrors(rdata.error.messages);
            return null;
        }
        if(rdata.response.data) return formatterBooking(rdata.response.data);
        return null;
    }
    async updateBooking({id, date, typeId, timeId, minutes, comment,
        userBalanceId, userId, isWriteOnTop,
        orderId, paymentTypeId, paymentStatusId, productId,
        type, }, cb) {
        let data = {
            'is_write_on_top': (isWriteOnTop) ? 1 : 0,
            'slotTime[date]': date,
            'slotTime[timeId]': timeId,
            'bookingData[minutes]': minutes,
        };
        if(type.slug === 'booking'){
            data['order[payment_type_id]'] = paymentTypeId;
            data['order[product_id]'] = productId;
            data['order[payment_id]'] = paymentStatusId;
        }else if(type.slug === 'certificate'){
            if(orderId) data['bookingData[order_id]'] = orderId;
        }
        if(comment)
            data['bookingData[comment]'] = comment;
        let rdata = await this._query(`admin/bookings/${id}`, "PATCH", data, 'searchParams');
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = formatterBookingErrors(rdata.error.messages);
            return null;
        }
        if(rdata.response.data) return formatterBooking(rdata.response.data);
        return null;
    }
    async cancelBooking({id}, cb) {
        let rdata = await this._query(`admin/bookings/${id}`, "DELETE");
        if (cb && rdata) cb(rdata);
        if (!rdata.result) return null;
        return rdata.response.data;
    }
    async processBooking({id}, cb) {
        let rdata = await this._query(`admin/bookings/${id}/process`, "DELETE");
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = rdata.error.messages;
            return null;
        }
        return rdata.response.data;
    }
    async tookPlaceBooking({id}, cb) {
        let rdata = await this._query(`admin/bookings/${id}/took-place`, "POST");
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = rdata.error.messages;
            return null;
        }
        return rdata.response.data;
    }
    async notTookPlaceBooking({id}, cb) {
        let rdata = await this._query(`admin/bookings/${id}/took-place`, "DELETE");
        if (cb && rdata) cb(rdata);
        if (!rdata.result){
            this.errors = rdata.error.messages;
            return null;
        }
        return rdata.response.data;
    }
}
