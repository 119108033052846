
const day = 1000*60*60*24;

export default class DateLib{
    static formatterDate(date){
        if(!date) return null;
        if(typeof date === 'string') date = new Date(date);
        if(!this.isValidDate(date)) return null;
        let day = date.toLocaleString("default", {day: '2-digit'});
        let month = date.toLocaleString("default", {month: '2-digit'});
        let year = date.toLocaleString("default", {year: 'numeric'});
        return `${year}-${month}-${day}`;
    }
    static formatterTime(date){
        if(!date) return null;
        if(typeof date == 'string') return date;
        return date.toLocaleString("ru", {
            hour: 'numeric',
            minute: 'numeric',
          })
    }
    static formatterDateTime(date, opt='en'){
        if(!date) return null;
        if(typeof date == 'string') return date;
        let day = date.toLocaleString("default", {day: '2-digit'});
        let month = date.toLocaleString("default", {month: '2-digit'});
        let year = date.toLocaleString("default", {year: 'numeric'});
        let hour = date.toLocaleString("default", {hour: 'numeric'});
        let minute = date.toLocaleString("default", {minute: '2-digit'});
        if(`${minute}`.length === 1) minute = `0${minute}`;
        return opt === 'ru'
            ? `${day}.${month}.${year} ${hour}:${minute}`
            : `${year}-${month}-${day} ${hour}:${minute}`
    }
    static getWeekdayNumber(date) {
        if (!date) return null;
        if (typeof date === 'string') date = new Date(date);
        if (!this.isValidDate(date)) return null;

        return date.getDay();
    }

    static equals(date1, date2){
        return (DateLib.formatterDate(date1) === DateLib.formatterDate(date2))
    }
    static isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    static get day(){
        return day;
    }
}
