import React, { useLayoutEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import BookingsHeader from "../bookings/__header";
import SlotTimes from '../slot-times';
import Bookings from '../bookings';
import BookingPanel from '../booking-panel';
import DateLib from "../../inc/DateLib";
import { loadDataSlotTime } from "../../reducerApi";
import { getDates, checkIsNeighbour, getDatesByDays} from "./actions";
import store from "../../reducers/store";
import './styles.css';
import './styles-grid.css';
import './styles-sticky.css';


export default function BookingContent(){
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const date = useSelector((state) => state.pageBooking.date);
    const roles = useSelector((state) => state.profile.roles);
    const isOpen = useSelector((state) => state.bookingPanel.isOpen);
    const isSearch = useSelector((state) => state.pageBooking.isSearch);

    useLayoutEffect(()=>{
        if(date){
            let datesSlotTimes = Object.keys(store.getState().pageBooking.slotTimes).map((key) => key);
            let dates = getDates(date, true).filter((date) => !datesSlotTimes.includes(date));
            let days = getDatesByDays(date);
            days.forEach((item)=>{
                loadDataSlotTime({dispatch, date: item.date, days: (dates.length === 1) ? 1 : item.days});
            });
        }
    }, [dispatch, date]);

    return <div className="booking-content">
        <div className={(!isOpen) ? "booking-content__left" : "booking-content__left"}>
            <div className="booking-content__content" ref={contentRef}>
                <BookingsHeader/>
                {(isSearch)?
                    <Bookings date={date}/>
                    :
                    <>
                    {((date) ? getDates(date) : []).map((_date)=>{
                        return <SlotTimes
                            key={_date}
                            date={_date}
                            roles={roles}
                            isActive={DateLib.equals(date, _date)}
                            isNeighbour={checkIsNeighbour(new Date(date), new Date(_date))}
                            parentRef={contentRef}
                        />
                    })}
                    </>
                }
            </div>
        </div>
        {(isOpen) ?
            <div className="booking-content__right">
                <BookingPanel />
            </div>
        : ''}
    </div>
}