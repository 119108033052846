import React from "react";

import ModalAction from "../modal-action";

export default function ModalCreate({
    actionBtnCreate,
    ...other
}){
    return (
        <ModalAction
            {...other}
            actionBtn={actionBtnCreate}
            labelBtn="Создать"
        />
    );
}