import PusherJS from 'pusher-js';
import config from "./../inc/Config";
import SlotTimes from './Channels/SlotTimes';

PusherJS.logToConsole = false;

const pusherJS = new PusherJS(config.pusher.key, {
    cluster: config.pusher.cluster,
  });
//console.log(pusherJS);
const channels = [SlotTimes];

export default class Pusher {
    constructor() {
        this.channels = {};
    }
    init(){
        channels.forEach((ChannelModel)=>{
            let channelPusherJS = pusherJS.subscribe(ChannelModel.NAME);
            let _channel = new ChannelModel({channel: channelPusherJS});
            _channel.init();
            this.channels[ChannelModel.NAME] = _channel;
        });
    }
}
