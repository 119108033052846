import React from "react";

import Date from "../__date";
import Time from "../__time";

import "./styles.css";

export default function BookingPanelDatetime({type, valids = {} }){
    return <div className="booking-panel__content-datetime-group">
        <Date errors={valids.date}/>
        <Time errors={valids.slotTimeId}/>
    </div>
}