import React from "react";

import TextField from '@mui/material/TextField';
import DateLib from '../../../inc/DateLib';

export default function InputDate({value, sx = {}, inputSx = {}, min = null, errors, ...other}){
    return <TextField 
        {...other}
        sx={{ margin: '5px', ...sx}}
        inputProps={{style: { ...inputSx}}} 
        size="small"
        type="date"
        value={DateLib.formatterDate(value)}
        min={DateLib.formatterDate(min)}
        InputLabelProps={{
            shrink: true,
        }}
        error={(errors) ? true : false}
        helperText={errors}
    />
}