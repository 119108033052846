import React from "react";

import BookingHeaderLeft from "./__left";
import BookingHeaderRight from "./__right";

import './styles.css';


export default function BookingHeader(){

    return <div className="booking-header">
        <BookingHeaderLeft/>
        <BookingHeaderRight/>
    </div>
    
}