import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputSelect from "../__select";
import { setPaymentStatusId } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelPaymentStatus({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const paymentStatuses = useSelector((state) => state.list.paymentStatuses);
    const paymentStatusId = useSelector((state) => state.bookingPanel.paymentStatusId);
    let items = [];
    Object.keys(paymentStatuses).forEach((key)=>{
        let item = paymentStatuses[key];
        items[key] = {
            onClick: (!isEdit)? null : () =>{
                dispatch(setPaymentStatusId(item.id));
            },
            label: item.name,
        }
    });
    return <InputSelect
        {...other}
        label="Статус"
        value={paymentStatusId}
        items={items}
    />
}