import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../__button";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ModalUserBalance from "../../user-balances/__index/user-balances__index-modal";
import { setUserBalanceId } from "./../../../reducers/BookingPanel/BookingPanelActions";

export default function BookingPanelUserBalance({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.bookingPanel.userId);
    const userBalanceId = useSelector((state) => state.bookingPanel.userBalanceId);
    const user = useSelector((state) => (userId && userId in state.list.users) ? state.list.users[userId] : null);
    const userBalances = useSelector((state) => (user) ? user.userBalances : []);
    const userBalance = (userBalanceId in userBalances) ? userBalances[userBalanceId] : null;
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openModal = () =>{ setIsOpenModal(true)}
    const closeModal = () =>{ setIsOpenModal(false)}
    const actionSelectUserBalance = (userBalance) => {
        dispatch(setUserBalanceId(userBalance.id));
        closeModal();
    }
    useEffect( () =>{
        if(!userBalance && userBalanceId) dispatch(setUserBalanceId(null));
    }, [dispatch, userBalanceId, userBalance]);
    return (<>
            <Button 
                {...other}
                Icon={AccountBalanceIcon}
                onClick={(!isEdit)? null : openModal}
            >
                {(userBalance) ? userBalance.type : "Выберите баланс"}
            </Button>
            <ModalUserBalance
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                userId={userId}
                actionSelectUserBalance={actionSelectUserBalance}
            />
        </>
    );
}