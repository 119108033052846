const consts = {
    CLEAR:                          "BOOKING_PANEL-CLEAR",
    CLEAR_BY_REQUEST:               "BOOKING_PANEL-CLEAR_BY_REQUEST",
    SET_IS_OPEN:                    "BOOKING_PANEL-SET_IS_OPEN",
    SET_IS_LOAD_UPDATE:             "BOOKING_PANEL-SET_IS_LOAD_UPDATE",
    SET_BOOKING_ID:                 "BOOKING_PANEL-SET_BOOKING_ID",
    SET_DATE:                       "BOOKING_PANEL-SET_DATE",
    SET_MINUTES:                    "BOOKING_PANEL-SET_MINUTES",
    SET_SLOT_TIME_ID:               "BOOKING_PANEL-SET_SLOT_TIME_ID",
    SET_TYPE_ID:                    "BOOKING_PANEL-SET_TYPE_ID",
    SET_USER_ID:                    "BOOKING_PANEL-SET_USER_ID",
    SET_USER_BALANCE_ID:            "BOOKING_PANEL-SET_USER_BALANCE_ID",
    SET_ORDER_ID:                   "BOOKING_PANEL-SET_ORDER_ID",
    SET_PRODUCT_ID:                 "BOOKING_PANEL-SET_PRODUCT_ID",
    SET_PAYMENT_TYPE_ID:            "BOOKING_PANEL-SET_PAYMENT_TYPE_ID",
    SET_PAYMENT_STATUS_ID:          "BOOKING_PANEL-SET_PAYMENT_STATUS_ID",
    SET_COMMENT:                    "BOOKING_PANEL-SET_COMMENT",
    SET_IS_WRITE_ON_TOP:            "BOOKING_PANEL-SET_IS_WRITE_ON_TOP",
};
  
export default consts;