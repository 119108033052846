import React, {useState} from "react";

import InputText from '../../input/__text';
import InputPhone from "../../input/__phone";
import InputEmail from "../../input/__email";

import ModalCreate from "../../modal-create";
import ApiUsers from "../../../api/Admin/Users";

import style from "./style";



export default function UserCreateModal({isOpenModal, closeModal, actionSuccessCreate}){
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('7');
    const [valids, setValids] = useState({});

    const actionEditName = (e) => {setName(e.target.value)}
    const actionEditSurName = (e) => {setSurname(e.target.value)}
    const actionEditMiddleName = (e) => {setMiddleName(e.target.value)}
    const actionEditEmail = (e) => {setEmail(e.target.value)}
    const actionEditPhone = (e) => {
        let value = e.target.value;
        if(value.length >= 1 && value.length <= 11) setPhone(value)
    }
    const actionCreateUser = async () => {
        setValids({});
        const apiUsers = ApiUsers.Store({name, surname, middleName, email, phone});
        const result = await apiUsers.getResponse();
        if(result){
            if(actionSuccessCreate)
                actionSuccessCreate(result);
            closeModal();
        }else setValids(apiUsers.errors.valids);
    }
    return (
        <ModalCreate
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            title="Новый пользователь"
            actionBtnCreate={actionCreateUser}
        >
            <InputText
                label="Фамилия"
                variant="standard"
                value={surname}
                onChange={actionEditSurName}
                sx={style.input}
                errors={valids.surname}
            />
            <InputText
                label="Имя"
                variant="standard"
                value={name}
                onChange={actionEditName}
                sx={style.input}
                errors={valids.name}
            />
            <InputText
                label="Отчество"
                variant="standard"
                value={middleName}
                onChange={actionEditMiddleName}
                sx={style.input}
                errors={valids.middleName}
            />
            <InputEmail
                label="Email"
                variant="standard"
                value={email}
                onChange={actionEditEmail}
                sx={style.input}
                errors={valids.email}
            />
            <InputPhone
                label="Телефон"
                variant="standard"
                value={(phone) ? `${phone}`: '7'}
                onChange={actionEditPhone}
                sx={style.input}
                errors={valids.phone}
            />
        </ModalCreate>
    );
}