import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InputDate from "./../../input/__date";
import DateLib from "../../../inc/DateLib";
import { setDate as setDateBookingPanel } from "../../../reducers/BookingPanel/BookingPanelActions";
import { setDate as setDatePageBooking } from "../../../reducers/PageBooking/PageBookingActions";

import style from "./style";
//import "./styles.css";

export default function BookingPanelDate({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const date = useSelector((state) => state.bookingPanel.date);
    const onChangeDate = (e) => {
        let _date = e.target.value;
        if(!DateLib.equals(_date, date)){
            dispatch(setDateBookingPanel(_date));
            dispatch(setDatePageBooking(_date));
        }
    };
    return (
        <div className="">
            <InputDate
                {...other}
                label="Дата"
                inputSx={style.input}
                value={date}
                onChange={(!isEdit)? null : onChangeDate}
            />
        </div>
    );
}