import ApiInit from "./ApiInit";
import ApiAuth from "./ApiAuth";
import { setDataLogout, setDataLogin } from "../inc/lib";
import store from "../reducers/store";

const apiAuth = new ApiAuth({});
export default class ApiInitByAuth extends ApiInit{
    static isLoadRefresh = false;
    get headers() {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${this.token}`,
        };
    }
    async _query(path, method, data, type = null, iRepeat = 0){
        this.setIsLoad(true);
        let result = await this.__query(path, method, data, type);
        if(!result.result && result.error.code === 401){
            if(await this.reAuth(iRepeat))
                result = await this._query(path, method, data, type, (iRepeat+1));
        }
        this.setIsLoad(false);
        return result;
    }
    async reAuth(iRepeat){
        if(ApiInitByAuth.isLoadRefresh){
            let i = 0;
            do{
                await new Promise(resolve => setTimeout(resolve, 100));
                i++;
            }while(ApiInitByAuth.isLoadRefresh && i < 1000)
            return true;
        }
        if(iRepeat > 2){
            await setDataLogout({dispatch: store.dispatch});
            return false;
        }
        ApiInitByAuth.isLoadRefresh = true;
        const data = await apiAuth.refresh();
        if(data){
            await setDataLogin({
                dispatch: store.dispatch,
                token: data.token,
                roles: data.roles,
                fullName: data.fullName,
            });
        }
        ApiInitByAuth.isLoadRefresh = false;
        return true;
    }

}
