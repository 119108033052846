import React from "react";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from "@mui/material/Typography";


import style from "./style";

export default function MyModal({isOpenModal, closeModal, children, title}){
    return (
        <Modal
            open={isOpenModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.modal}>
                {title ? 
                <Typography variant="h6" component="h2">{title}</Typography>
                : ''}
                <div>
                    {children}
                </div>
            </Box>
        </Modal>
    );
}