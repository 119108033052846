import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../__button";
import FlightIcon from "@mui/icons-material/Flight";
import ModalCertificate from "../../orders/_certificate/__index/orders_certificate__index-modal";
import { setOrderId } from "./../../../reducers/BookingPanel/BookingPanelActions";
import { updateOrder } from "./../../../reducers/List/ListActions";

export default function BookingPanelCertificate({isEdit = true, ...other}){
    const dispatch = useDispatch();
    const orderId = useSelector((state) => state.bookingPanel.orderId); 
    const order = useSelector((state) => (orderId in state.list.orders) ? state.list.orders[orderId] : null); 
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openModal = () =>{ setIsOpenModal(true)}
    const closeModal = () =>{ setIsOpenModal(false)}
    const actionSelectOrder = (order) => {
        dispatch(updateOrder(order.id, order));
        dispatch(setOrderId(order.id));
        closeModal();
    }
    return (<>
            <Button 
                {...other}
                Icon={FlightIcon}
                onClick={(!isEdit)? null : openModal}
            >
                {(order && order.certificate) ? order.certificate.code : "Сертификат"}
            </Button>
            <ModalCertificate
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                actionSelectOrder={actionSelectOrder}
            />
        </>
    );
}