import CONFIG from "./../inc/Config";
import { setIsLoad } from "./../reducers/App/AppActions";
import store from "../reducers/store";

export default class ApiInit{
    errors = null;
    constructor({ baseUrl = "/api", version = "/v0" }) {
        this._baseUrl = CONFIG.serverUrl + baseUrl + version;
    }
    get headers() {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
    }
    get token() {
        return store.getState().app.token;
    }
    clearErrors(){
        this.errors = null;
    }
    setIsLoad(status = true){
        store.dispatch(setIsLoad(status));
    }
    async __query(path, method, data, type = null) {
        this.clearErrors();
        let url = `${this._baseUrl}/${path}`;
        let sendData = await this.getSendData({type, data});
        let options = {
            method: method,
            headers: this.headers,
        };
        if(sendData){
            if(type === "searchParams") url += `?${sendData}`;
            else options.body = sendData;
        }
        try {
            let response = await fetch(url, options);
            let json = await response.json();
            if (!response.ok) {
                console.error(
                    `AJAX error, url: ${url}, status: ${response.status}`,
                    response
                );
                return { 
                    result: false, 
                    message: "Ошибка сервера",
                    error: {
                        code: response.status,
                        messages: (json.errors) ? json.errors : [],
                    }
                };
            }
            this.clearErrors();
            return { result: true, response: json };
        } catch (error) {
            console.error(`APP: AJAX general error, url: ${url}`, error);
            return { result: false, message: "Ошибка при запросе на сервер" };
        }
    }
    async _query(path, method, data, type = null){
        this.setIsLoad(true);
        let result = await this.__query(path, method, data, type);
        this.setIsLoad(false);
        return result;
    }
    async __queryTemp(data){
        await new Promise(r => setTimeout(r, 2000));
        return {
            result: true,
            data: {data: []},
        };
    }


    async getSendData({type, data}){
        let sendData = null;
        if(type === "formData") {
            sendData = new FormData();
            Object.keys(data).forEach((key) => {
                sendData.append(key, data[key]);
            });
        }else if(type === "searchParams"){
            sendData = new URLSearchParams();
            Object.keys(data).forEach((key) => {
                sendData.append(key, data[key]);
            });
        }
        else sendData = JSON.stringify(data);
        return sendData;
    }
    pasreParamsForGet(params){
        if(!params) return '';
        let _params = '';
        Object.keys(params).forEach((key)=>{
            let value = params[key];
            if(Array.isArray(value)){
                value.forEach((item) =>{
                    if(_params.length) _params+=`&${key}[]=${item}`;
                    else _params+=`${key}[]=${item}`;
                });
            }else if((value !== undefined) && (value.length || typeof value === 'number')){
                if(_params.length) _params+=`&${key}=${value}`;
                else _params+=`${key}=${value}`;
            }
        });
        return _params;
    }
}
