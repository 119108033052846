import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./reducers/store";
import WebSocket from "./webSocket";
const root = ReactDOM.createRoot(document.getElementById('root'));
const webSocket = new WebSocket();
webSocket.init();


root.render(
  <React.StrictMode>
    <Provider store={store} key="provider">
      <App/>
    </Provider>
  </React.StrictMode>
);
