import React, {useState, useEffect} from "react";

import InputText from '../../input/__text';
import ModalList from '../../modal-list';

import ApiProducts from "../../../api/Admin/Products";

import style from "./style-modal";

export default function ProductsIndexModal({actionSelectProduct, minutes, isOpenModal, ...other}){
    const [products, setProducts] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    useEffect(()=>{
        const loadProducts = async () => {
            const apiProducts = ApiProducts.Index({minutes, categoryName});
            const data = await apiProducts.getResponse();
            if(data){
                data.sort((a,b) => {
                    const nameA = a.category.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.category.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                })
                setProducts(data);
            }
        }
        if(
            isOpenModal
            && minutes
        ) loadProducts();
        else setProducts([]);
    }, [minutes, categoryName, isOpenModal]);
    const actionEditCategoryName = (e) => {setCategoryName(e.target.value)}
    const actionClear = () => {
        setCategoryName('');
    }
    return <ModalList
        {...other}
        isOpenModal={isOpenModal}
        id="products"
        title="Тарифы"
        headers={['Стоимость, руб', 'Тариф']}
        list={products.map((product)=>{
            return {
                click: (actionSelectProduct) ? () => {
                    actionSelectProduct(product);
                }: null,
                columns: [
                    product.price,
                    product.category.name,
                ],
            }
        })}
        actionClear={actionClear}
    >
        <InputText
            label="Тариф"
            variant="standard"
            value={categoryName}
            onChange={actionEditCategoryName}
            sx={style.input}
        />
    </ModalList>
}