import React from "react";

import ModalAction from "../modal-action";


export default function ModalEdit({
    actionBtnSave,
    ...other
}){
    return <ModalAction
        {...other}
        actionBtn={actionBtnSave}
        labelBtn="Сохранить"
    />
}