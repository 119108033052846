import React, {useState, useEffect} from "react";

import InputText from '../../../input/__text';
import InputPhone from "../../../input/__phone";
import InputEmail from "../../../input/__email";
import ModalList from '../../../modal-list';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import ApiOrders from "../../../../api/Admin/Orders";
import DateLib from "../../../../inc/DateLib";
import style from "./style-modal";


const IconCertificate = ({order}) => {
    if(order.certificate.isActive!= null && !order.certificate.isActive)
        return <ErrorOutlineIcon sx={{color: "#ae3a0d;"}}/>
    if(order.certificate.dateUse)
        return <DoneIcon color="success"/>;
    if(order.certificate.isManualCheck)
        return <PriorityHighIcon sx={{color: "#FC0"}}/>;
    return '';
}

export default function OrdersCertificateIndexModal({actionSelectOrder, ...other}){
    const [orders, setOrders] = useState([]);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('7');
    
    useEffect(()=>{
        const loadOrder = async () => {
            const apiOrders = ApiOrders.Index({name,code,email,phone,type: 1});
            const data = await apiOrders.getResponse();
            if(data){
                data.sort((order) => {
                    if(order.certificate.dateUse) return 1;
                    return -1;
                })
                setOrders(data);
            }
        }
        if(
            code.length > 1
            || name.length > 2
            || email.length > 2
            || phone.length > 2
            ) loadOrder();
        else setOrders([]);
    }, [code, name, email, phone]);
    const actionEditCode = (e) => {
        setCode(e.target.value)
    }
    const actionEditName = (e) => {setName(e.target.value)}
    const actionEditEmail = (e) => {setEmail(e.target.value)}
    const actionEditPhone = (e) => {
        let value = e.target.value;
        if(value.length >= 1 && value.length <= 11) setPhone(value)
    }
    const actionClear = () => {
        setCode('');
        setName('');
        setEmail('');
        setPhone('7');
    }
    return <ModalList
        {...other}
        id="certificates"
        title="Сертификаты"
        headers={['', 'Номер', 'Минут', 'Телефон', 'Окончание', '']}
        list={orders.map((order)=>{
            return {
                click: (actionSelectOrder && !order.certificate.dateUse && 
                    (order.certificate.isActive == null || order.certificate.isActive)) ? () => {
                    actionSelectOrder(order);
                }: null,
                columns: [
                    <IconCertificate order={order}/>,
                    order.certificate.code,
                    (order.totalMinutes) ? order.totalMinutes : null,
                    order.phone,
                    DateLib.formatterDateTime(order.certificate.dateEnd),
                    (order.marketing && order.marketing.urlAmoCrm) ? 
                        <a href={order.marketing.urlAmoCrm} target="_blank" rel="noreferrer"><PeopleAltIcon/></a> 
                    : null,
                ],
            }
        })}
        actionClear={actionClear}
    >
        <InputText
            label="Сертификат"
            variant="standard"
            value={code}
            onChange={actionEditCode}
            sx={style.input}
        />
        <InputText
            label="Имя"
            variant="standard"
            value={name}
            onChange={actionEditName}
            sx={style.input}
        />
        <InputEmail
            label="Email"
            variant="standard"
            value={email}
            onChange={actionEditEmail}
            sx={style.input}
        />
        <InputPhone
            label="Телефон"
            variant="standard"
            value={phone}
            onChange={actionEditPhone}
            sx={style.input}
        />
    </ModalList>
}