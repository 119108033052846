import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import FlashOn from "@mui/icons-material/FlashOn";
import Button from "../__button";
import ModalProducts from "../../products/__index/products__index-modal";
import { setProductId } from "./../../../reducers/BookingPanel/BookingPanelActions";
import { updateProduct } from "./../../../reducers/List/ListActions";

export default function BookingPanelProduct({isEdit = true, ...other}){
    const dispatch = useDispatch(); 
    const minutes = useSelector((state) => state.bookingPanel.minutes);
    const productId = useSelector((state) => state.bookingPanel.productId);
    const product = useSelector((state) => (productId in state.list.products) ? state.list.products[productId] : null); 
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openModal = () =>{ setIsOpenModal(true)}
    const closeModal = () =>{ setIsOpenModal(false)}
    const actionSelectProduct = (product) => {
        dispatch(updateProduct(product.id, product));
        dispatch(setProductId(product.id));
        closeModal();
    }
    useEffect( () =>{
        if(!product || !minutes ||
            (product.minutes !== minutes)
        ) dispatch(setProductId(null));
    }, [dispatch, product, minutes]);
    
    return (<>
        <Button 
            {...other}
            Icon={FlashOn}
            onClick={(!isEdit)? null : openModal}
        >
            {(product) ? product.category.name : "Тариф"}
        </Button>
        <ModalProducts
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            minutes={minutes}
            actionSelectProduct={actionSelectProduct}
        />
        </>
    );
}